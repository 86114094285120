import {DataSourceResponse} from "./dataSourceRestCalls";
import {DataSource} from "./dataSourceQueries";

export const mapDataSourceResponseToDataSource = (r: DataSourceResponse): DataSource => {
    return {
        id: r.id,
        name: r.name,
        variableName: r.variableName,
        connection: {
            url: r.url,
            urlType: r.urlType,
            method: r.method,
        },
        auth: r.auth,
        fileType: r.fileType,
        csvSettings: {
            hasHeaderLine: r.csvSettings?.hasHeaderLine,
            quote: r.csvSettings?.quote,
            delimiter: r.csvSettings?.delimiter === "\t"
                ? "\\t"
                : r.csvSettings?.delimiter,
        },
        createdAt: r.createdAt,
        createdBy: r.createdBy,
        updatedAt: r.updatedAt,
        updatedBy: r.updatedBy,
    };
};
