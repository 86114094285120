import {useOrganisationModuleTranslation} from "../../../../util/i18n/useModuleTranslation";
import {User, useUpdateUserMutation} from "../../../../rest/user/userQueries";
import {ErrorAlert} from "./ErrorAlert";
import {SMALL_DIALOG_WIDTH} from "../../../../state/constants";
import {FormLine} from "../../../common/mui/form/FormLine";
import {SimpleDialog} from "../../../common/mui/dialog/SimpleDialog";
import {mapRole, useEditUserForm, UserRole} from "./editUserForm";
import {SingleSelect} from "../../../common/mui/inputs/SingleSelect";
import {StremoTextField} from "../../../common/mui/inputs/StremoTextField";

const toFormRole = (role: string): UserRole => {
    switch (role) {
        case "Admin":
            return UserRole.ADMIN;
        case "Developer":
            return UserRole.DEVELOPER
        case "Viewer":
            return UserRole.VIEWER
    }
};

interface EditUserModalProps {
    readonly user: User;
    readonly groupPath: string;
    readonly onClose: () => void;
}

export const EditUserModal = (props: EditUserModalProps) => {
    const {onClose, user, groupPath} = props;
    const {t} = useOrganisationModuleTranslation("userList.editModal");
    const methods = useEditUserForm({
            username: user.username,
            surname: user.surname,
            role: toFormRole(user.roles[0]),
            firstName: user.firstName,
            email: user.email
        }
    );
    const mutation = useUpdateUserMutation(groupPath, user.id);

    const onSubmit = () => {
        mutation.mutateAsync({
            id: user.id,
            email: methods.email,
            firstName: methods.firstName,
            surname: methods.surname,
            role: mapRole(methods.role),
        })
            .then(onClose)
    }

    return (
        <SimpleDialog
            title={t("title", {username: user.username})}
            open={true}
            htmlMinWidth={SMALL_DIALOG_WIDTH}
            onClose={onClose}
            aria-labelledby="edit-user-of-organisation"
            footerActionProps={{
                cancelButton: {text: t("cancel"), onClick: onClose},
                submitButton: {text: t("submit"), onClick: onSubmit, disabled: !methods.isValid()}
            }}
        >
            {
                mutation.isError && (
                    <ErrorAlert error={mutation.error} translationBaseKey="userList.editModal"/>
                )
            }
            <FormLine
                label={t("fields.email.label")}
                required={false}
                isDirty={methods.dirty.email}
                hasError={!!methods.errors.email}
                errorMessage={methods.errors.email && t(`fields.email.validation.${methods.errors.email}`)}
            >
                <StremoTextField
                    autoFocus={true}
                    type="email"
                    value={methods.email}
                    onChange={e => methods.on.emailChange(e.target.value)}
                />

            </FormLine>
            <FormLine
                label={t("fields.firstName.label")}
                isDirty={methods.dirty.firstName}
                hasError={!!methods.errors.firstName}
                errorMessage={methods.errors.firstName && t(`fields.firstName.validation.${methods.errors.firstName}`)}
            >
                <StremoTextField
                    required={true}
                    value={methods.firstName}
                    onChange={(e) => methods.on.firstNameChange(e.target.value)}
                />
            </FormLine>
            <FormLine
                label={t("fields.surname.label")}
                isDirty={methods.dirty.surname}
                hasError={!!methods.errors.surname}
                errorMessage={methods.errors.surname && t(`fields.surname.validation.${methods.errors.surname}`)}
            >

                <StremoTextField
                    required={true}
                    fullWidth={true}
                    value={methods.surname}
                    onChange={e => methods.on.surnameChange(e.target.value)}/>
            </FormLine>

            <FormLine label={t("fields.roles.label")} hasError={!!methods.errors.role}>
                <SingleSelect<UserRole>
                    value={methods.role}
                    options={[
                        {
                            value: UserRole.ADMIN,
                            label: "Admin"
                        },
                        {
                            value: UserRole.DEVELOPER,
                            label: "Developer",
                        },
                        {
                            value: UserRole.VIEWER,
                            label: "Viewer"
                        }
                    ]}
                    onChange={methods.on.roleChange}
                />
            </FormLine>
        </SimpleDialog>
    );
};
