import {Dialog, styled} from "@mui/material";

export const StyledDialog = styled(Dialog)(({theme, htmlminwidth}) => ({
    "& .MuiDialogActions-root": {
        padding: "24px 24px 24px 24px",
    },
    "& .MuiDialogContent-root": {
        overflowY: "auto",
    },
    "& form": {
        padding: "0",
    },
    "& .MuiDialog-paper": {
        position: "absolute",
        top: 100,
        minWidth: htmlminwidth,
        maxHeight: window.innerHeight - 200
    },
}));
