export enum ProjectDetailsTab {
    OVERVIEW = "OVERVIEW",
    FILES = "FILES",
    SETTINGS = "SETTINGS",
    SIMPLE_FORM = "SIMPLE_FORM",
    CALCULATIONS = "CALCULATIONS",
    CALCULATION = "CALCULATION",
    CALCULATION_WORKSPACE = "CALCULATION-WORKSPACE",
    CALCULATION_LOGS = "CALCULATION_LOGS",
    DATA_SOURCES = "DATA_SOURCES",
    OUTPUT_SOURCES = "OUTPUT_SOURCES",
    VARIABLES = "VARIABLES",
    SCHEDULING = "SCHEDULING",
}

export enum CreatedOverviewObjectType {
    FOLDER = "FOLDER",
    PROJECT = "PROJECT",
}
