import {useMutation, useQuery, useQueryClient} from "react-query";
import {
    callAddProject,
    callDeleteProject,
    callGetProject,
    callGetProjects,
    callUpdateProject
} from "./projectRestCalls";
import {ExceptionResponse} from "../exceptions";

export enum ProjectVisibility {
    PRIVATE = "PRIVATE",
    ORGANISATION = "ORGANISATION",
}

export enum ProjectComponentType {
    CALCULATION = "CALCULATION",
    VARIABLE = "VARIABLE",
    DATA_SOURCE = "DATA_SOURCE",
    FILE = "FILE",
    OUTPUT_CHANNEL = "OUTPUT_CHANNEL",
    SCHEDULING = "SCHEDULING"
}

export interface ProjectComponents {
    readonly lastCalculationSuccessfully?: boolean;
    readonly lastCalculationFinished?: string;
    readonly numberOfCalculations: number;
    readonly numberOfDataSources: number;
    readonly numberOfFiles: number;
    readonly numberOfOutputChannels: number;
    readonly numberOfScheduledJobs: number;
    readonly numberOfVariables: number;
}

const componentTypeToComponentsObjectKey = (type: ProjectComponentType): string => {
    switch (type) {
        case ProjectComponentType.CALCULATION:
            return "numberOfCalculations";
        case ProjectComponentType.DATA_SOURCE:
            return "numberOfDataSources";
        case ProjectComponentType.FILE:
            return "numberOfFiles";
        case ProjectComponentType.OUTPUT_CHANNEL:
            return "numberOfOutputChannels";
        case ProjectComponentType.SCHEDULING:
            return "numberOfScheduledJobs";
        case ProjectComponentType.VARIABLE:
            return "numberOfVariables"
    }
}

export interface Project {
    readonly id: string;
    readonly name: string;
    readonly visibility: ProjectVisibility;
    readonly description?: string;
    readonly components: ProjectComponents;
    readonly createdAt: string;
    readonly createdBy: string;
    readonly updatedAt?: string;
    readonly updatedBy?: string;
}

const getProjectListQuery = () => "projects";
export const getProjectDetailsQuery = (projectId: string) => `project-${projectId}`;

export const useGetProjectList = () => {
    const queryKey = getProjectListQuery();
    return useQuery<Project[], ExceptionResponse>(queryKey, (): Promise<Project[]> => {
        return callGetProjects();
    });
};

export const useGetProjectDetails = (uuid: string) => {
    const queryKey = getProjectDetailsQuery(uuid);
    return useQuery<Project, ExceptionResponse>(queryKey, () => {
        return callGetProject(uuid)
    });
};

export interface CreateProjectInput {
    readonly name: string;
    readonly visibility: ProjectVisibility;
    readonly description?: string;
}

export const useAddProjectMutation = () => {
    const queryClient = useQueryClient();
    const createProject = (projectInput: CreateProjectInput) => callAddProject(projectInput);

    return useMutation<Project, ExceptionResponse, CreateProjectInput>(createProject, {
        onSuccess: (data) => {
            queryClient.setQueriesData<Project[] | undefined>(getProjectListQuery(), (prev = []) => [...prev, data])
        },
    });
};

export const useUpdateProjectMutation = (projectId: string) => {
    const queryClient = useQueryClient();
    const updateProject = (projectInput: Partial<CreateProjectInput>) => callUpdateProject(projectId, projectInput)

    return useMutation<Project, ExceptionResponse, Partial<CreateProjectInput>>(updateProject, {
        onSuccess: (data) => {
            queryClient.setQueriesData(getProjectDetailsQuery(projectId), () => data)
            queryClient.setQueriesData<Project[] | undefined>(getProjectListQuery(), (prev = []) => {
                return prev.map(it => it.id === data.id ? data : it)
            })
        },
    });
};

export const useDeleteProjectMutation = (projectId: string) => {
    const queryClient = useQueryClient();
    const deleteProject = () =>
        callDeleteProject(projectId);

    return useMutation<void, ExceptionResponse, void>(deleteProject, {
        onSuccess: () => {
            queryClient.invalidateQueries(getProjectListQuery());
        },
    });
};


export interface UseUpdateProjectComponents {
    readonly increment: (component: ProjectComponentType) => void;
    readonly decrement: (component: ProjectComponentType) => void;
}

export const useUpdateProjectComponents = (projectId: string): UseUpdateProjectComponents => {
    const queryClient = useQueryClient();
    const update = (component: ProjectComponentType, diff: number) => {
        queryClient.setQueriesData<Project>(getProjectDetailsQuery(projectId), (old) => {
            const componentObjectKey = componentTypeToComponentsObjectKey(component);
            let components = old.components;
            components[componentObjectKey] = components[componentObjectKey] + diff
            return {
                ...old,
                components
            }
        })
    };
    return {
        increment: (component) => update(component, 1),
        decrement: (component) => update(component, -1)
    }
}