import {useHelp} from "../../../../../helpTextFramework";

import HelpIcon from "@mui/icons-material/Help";
import {Tooltip} from "@mui/material";

interface HelpToolTipProps {
    readonly namespace?: string;
    readonly helpKey: string
    readonly emptyText?: string;
    readonly className?: string;
}

export const HelpToolTip = (props: HelpToolTipProps) => {
    const {emptyText, helpKey, namespace, className} = props;
    const {h} = useHelp(namespace)


    return (
        <Tooltip title={h(helpKey, emptyText)} className={className} arrow={true} placement="top">
            <HelpIcon/>
        </Tooltip>
    )
}
