import {Navbar} from "../components/common/mui/navbar/Navbar";

import {useOverviewModuleTranslation} from "../util/i18n/useModuleTranslation";
import {MainContentArea} from "../components/common/mui/mainLayout/MainContentArea";
import {OverviewSidebar} from "../components/common/overviewSidebar/OverviewSidebar";
import {ProjectList} from "../components/projectOverview/projectList/ProjectList";
import {Page} from "../components/common/mui/page/Page";
import {useState} from "react";
import {CreateProjectDialog} from "../components/projectOverview/createProject/CreateProjectDialog";
import {TabHeader} from "../components/common/mui/tabHeader/TabHeader";

export const ProjectOverviewPage = () => {
    const {t} = useOverviewModuleTranslation();

    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const onModalClose = () => setModalVisible(false);
    const openModal = () => setModalVisible(true);
    const [searchText, setSearchText] = useState<string>();
    return (
        <Page>
            <Navbar title={t("projects.overview")}/>
            <MainContentArea
                sidebar={<OverviewSidebar/>}
            >
                <TabHeader
                    title={t("projects.title")}
                    showAddButton={true}
                    addButtonText={t("projects.createElement")}
                    onAddButtonClick={openModal}
                    showSearchField={true}
                    onSearchFieldChange={setSearchText}
                    searchFieldPlaceholder={t("projects.search")}
                />
                <ProjectList searchText={searchText}/>
            </MainContentArea>

            <CreateProjectDialog visible={modalVisible} onClose={onModalClose}/>
        </Page>
    );
};
