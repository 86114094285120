import {
    ExceptionResponse,
    isCalculationException,
    isFileStorageException,
    isInputValidationException,
    isRemoteRequestException,
    toCalculationException,
    toExceptionResponse,
    toFileStorageException,
    toForbiddenException,
    toInputValidationException,
    toInternalException,
    toNotAllowedException,
    toNotFoundException,
    toOfflineException,
    toRemoteRequestException
} from "./exceptions";
import {AxiosError} from "axios";
import configuredAxios from "./configuredAxios";

export const initAxiosErrorHandling = () => {
    configuredAxios.interceptors.response.use(
        response => Promise.resolve(response),
        (error: AxiosError) => Promise.reject(handleError(error))
    );
}

const handleError = (error: AxiosError): ExceptionResponse => {
    if (error.response === undefined) {
        return toOfflineException()
    }

    const {status, data} = error.response;

    switch (status) {
        case 400:
            return handle400Error(data, status);
        case 401:
            return toNotAllowedException();
        case 403:
            return toForbiddenException(data, status);
        case 404:
            return toNotFoundException(data, status);
    }
    if (status >= 500 && status < 600) {
        console.warn("there is an internal exception: ", error.response)
        return toInternalException(data, status);
    }
    console.warn("could not handle exception: ", error.response)
    return toExceptionResponse(data, status);
}

const handle400Error = (data: any, status: number) => {
    const translationCode = data?.translationCode
    if (translationCode === undefined) {
        return toExceptionResponse(data, status)
    }
    if (isRemoteRequestException(data)) {
        return toRemoteRequestException(data, status);
    }
    if (isInputValidationException(data)) {
        return toInputValidationException(data, status);
    }
    if (isCalculationException(data)) {
        return toCalculationException(data, status);
    }
    if (isFileStorageException(data)) {
        return toFileStorageException(data, status);
    }
    return toExceptionResponse(data, status)
}

export interface Page<T> {
    readonly content: T[];
    readonly empty: boolean;
    readonly first: boolean;
    readonly last: boolean;
    readonly number: number;
    readonly numberOfElements: number;
    // ignored: pageable
    readonly size: number;
    readonly sort: PageSort;
    readonly totalElements: number;
    readonly totalPages: number;
}

export interface PageSort {
    readonly sorted: boolean;
    readonly unsorted: boolean;
    readonly empty: boolean;
}

export function emptyPage<T>(): Page<T> {
    return {
        content: [],
        empty: true,
        first: true,
        last: true,
        number: 0,
        numberOfElements: 0,
        size: 0,
        sort: {
            sorted: true,
            unsorted: false,
            empty: true,
        },
        totalElements: 0,
        totalPages: 0
    };
}
