import {styled} from "@mui/styles";
import {ReactNode} from "react";
import {Theme} from "@mui/material";
import {DARK_MODE_BACKGROUND_COLOR, LIGHT_MODE_BACKGROUND_COLOR} from "../../../../mui-styles/muiStyles";

const MainContentRootDiv = styled("div")({
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "stretch",
    width: "100%",
    height: "100%",
    overflowY: "auto"
});
const SidebarWrapperDiv = styled("div")({
    maxWidth: "500px",
    order: 1,
});
const ContentWrapperDiv = styled("div")(({theme}) => ({
    width: "100%",
    order: 2,
    flex: "2 0",
    backgroundColor: (theme as Theme).palette.mode === "dark" ? DARK_MODE_BACKGROUND_COLOR : LIGHT_MODE_BACKGROUND_COLOR,
    padding: "20px 50px",
    overflowY: "auto"
}));

export interface MainContentAreaProps {
    readonly sidebar?: ReactNode;
    readonly children: ReactNode;
}

export const MainContentArea = (props: MainContentAreaProps) => {
    const {sidebar, children} = props;

    return (
        <MainContentRootDiv>
            {
                sidebar && (
                    <SidebarWrapperDiv>
                        {sidebar}
                    </SidebarWrapperDiv>
                )
            }
            <ContentWrapperDiv>
                {children}
            </ContentWrapperDiv>
        </MainContentRootDiv>
    );
};
