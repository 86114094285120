import {Loading} from "../common/mui/loading/Loading";
import {styled} from "@mui/styles";
import {Theme} from "@mui/material";
import {DARK_MODE_BACKGROUND_COLOR, LIGHT_MODE_BACKGROUND_COLOR} from "../../mui-styles/muiStyles";

const StartLoadingDiv = styled("div")(({theme}) => ({
    width: "100%",
    height: "100%",
    textAlign: "center",
    background: (theme as Theme).palette?.mode  === "dark" ? DARK_MODE_BACKGROUND_COLOR : LIGHT_MODE_BACKGROUND_COLOR,
    borderRadius: "4px",
    padding: "30px 50px",
    margin: "20px 0",
}));

export const StartLoadingScreen = () => {
    return (
        <StartLoadingDiv>
            <Loading/>
        </StartLoadingDiv>
    );
};
