import {Navbar} from "../components/common/mui/navbar/Navbar";
import {useRunnerModuleTranslation} from "../util/i18n/useModuleTranslation";
import {RunnerOverview} from "../components/runners/RunnerOverview";
import {Page} from "../components/common/mui/page/Page";

export const RunnersPage = () => {
    const {t} = useRunnerModuleTranslation();
    return (
        <Page>
            <Navbar title={t("navbar")}/>
            <RunnerOverview/>
        </Page>
    );
};
