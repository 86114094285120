import {ChangeEventHandler, useState} from "react";

import {useHistory} from "react-router-dom";
import {CreatedOverviewObjectType} from "../../../state/model";
import {DEFAULT_PROJECT_VISIBILITY, MIDDLE_DIALOG_WIDTH} from "../../../state/constants";
import {ProjectVisibility, useAddProjectMutation} from "../../../rest/project/projectQueries";
import {useOverviewModuleTranslation} from "../../../util/i18n/useModuleTranslation";
import {SimpleDialog} from "../../common/mui/dialog/SimpleDialog";
import {Alert} from "@mui/material";
import {SingleSelect} from "../../common/mui/inputs/SingleSelect";
import {StremoTextField} from "../../common/mui/inputs/StremoTextField";
import {FormLine} from "../../common/mui/form/FormLine";
import {HelpToolTip} from "../../common/mui/help/HelpToolTip";

export interface CreateProjectDialogProps {
    readonly visible: boolean;
    readonly onClose: () => void;
}

export const CreateProjectDialog = (props: CreateProjectDialogProps) => {
    const {visible, onClose} = props;
    const {t} = useOverviewModuleTranslation("createObjectModal");

    const type = CreatedOverviewObjectType.PROJECT;
    const [name, setName] = useState<string>();
    const [visibility, setVisibility] = useState<ProjectVisibility>(DEFAULT_PROJECT_VISIBILITY);
    const [description, setDescription] = useState<string>();

    const addProject = useAddProjectMutation();

    const onNameInputChange: ChangeEventHandler<HTMLInputElement> = (event) => setName(event.target.value);
    const onDescriptionInputChange: ChangeEventHandler<HTMLInputElement> = (event) => setDescription(event.target.value);

    const history = useHistory();

    const isSubmitEnabled = () => {
        if (type === undefined || name === undefined) {
            return false;
        }
        return name.trim().length !== 0;
    };

    const onOk = () => addProject.mutateAsync({name, visibility, description}).then((data) => {
        history.push("/projects/" + data.id);
    });

    return (
        <SimpleDialog
            open={visible}
            onClose={onClose}
            title={t("title")}
            ariaLabelledBy="create-project-modal"
            htmlMinWidth={MIDDLE_DIALOG_WIDTH}
            footerActionProps={{
                cancelButton: {
                    text: t("cancelText"),
                    onClick: props.onClose,
                },
                submitButton: {
                    text: t("okText"),
                    onClick: onOk,
                    disabled: !isSubmitEnabled()
                },
            }}>
            {
                addProject.error && (
                    <Alert severity="error">{t("response.error." + addProject.error.translationCode)}</Alert>
                )
            }

            <FormLine label={t("name.label")}>
                <StremoTextField
                    autoFocus={true}
                    fullWidth={true}
                    defaultValue={name}
                    onChange={onNameInputChange}
                />
            </FormLine>
            <FormLine
                label={t("visibility.label")}
                helpIcon={<HelpToolTip namespace="project/general" helpKey="visibility"/>}
            >
                <SingleSelect<ProjectVisibility> value={visibility} options={[
                    {
                        value: ProjectVisibility.PRIVATE,
                        label: t(`visibility.value.${ProjectVisibility.PRIVATE}`),

                    },
                    {
                        value: ProjectVisibility.ORGANISATION,
                        label: t(`visibility.value.${ProjectVisibility.ORGANISATION}`),

                    }]} onChange={setVisibility}/>
            </FormLine>
            <FormLine label={t("description.label")} required={false}>

                <StremoTextField fullWidth={true}
                                 defaultValue={description}
                                 minRows={4}
                                 multiline={true}
                                 onChange={onDescriptionInputChange}
                />
            </FormLine>
        </SimpleDialog>
    );
};
