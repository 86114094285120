import {createContext, useContext} from "react";

export const defaultNamespace = "help";
export interface ComplexHelpTextItem {
    readonly title: string;
    readonly content: string | string[];
}

export type HelpTextItem = ComplexHelpTextItem | string;

export interface Namespace {
    [key: string]: Namespace | HelpTextItem;
}

export interface LngBasedNamespace {
    [key: string]: Namespace
}

export interface LngBasedNamespaces {
    [key: string]: LngBasedNamespace
}

export interface LoadingNamespace {
    readonly lng: string;
    readonly namespace: string;
}

export interface HelpTextContextContent {
    readonly setLanguage: (lng: string) => void;
    readonly getLanguage: () => string;
    readonly getNamespaceContent: (namespace: string) => Promise<Namespace | undefined>;
    readonly isNamespaceLoading: (namespace: string) => boolean;
    readonly isNamespaceLoaded: (namespace: string) => boolean;
    readonly isNamespaceExists: (namespace: string) => Promise<boolean>;
    readonly loadNamespace: (namespace: string) => Promise<boolean>
}

export const HelpTextContext = createContext<HelpTextContextContent>(undefined)
export const useHelpTextContext = () => useContext(HelpTextContext);
