import {useMutation, useQuery, useQueryClient} from "react-query";
import {mapVariable} from "./variableMapper";
import {callAddVariable, callDeleteVariable, callGetVariables, callUpdateVariable} from "./variableRestCalls";
import {DataSourceFileType} from "../dataSources/dataSourceQueries";
import {ProjectComponentType, useUpdateProjectComponents} from "../project/projectQueries";
import {ExceptionResponse, InputValidationExceptionResponse} from "../exceptions";

export enum VariableType {
    STRING = "STRING",
    BOOLEAN = "BOOLEAN",
    NUMBER = "NUMBER",
    DECIMAL = "DECIMAL",
    SECRET = "SECRET"
}

export enum VariableOverrideType {
    STATIC = "STATIC",
    OVERRIDABLE = "OVERRIDABLE",
}

export interface Variable {
    readonly id: string;
    readonly name: string;
    readonly type: VariableType;
    readonly overrideType: VariableOverrideType;
    readonly value?: string;
    readonly createdAt: string;
    readonly createdBy: string;
    readonly updatedAt?: string;
    readonly updatedBy?: string;
}

export interface AddVariableInput {
    readonly name: string;
    readonly type: VariableType;
    readonly overrideType: VariableOverrideType;
    readonly value?: string;
}

export interface UpdateVariableInput {
    readonly id: string;
    readonly name: string;
    readonly type: VariableType;
    readonly overrideType: VariableOverrideType;
    readonly value?: string;
    readonly valueUpdated: boolean;
}

const getVariableListKey = (projectId: string) => `project-${projectId}-variableList`;
const getVariableDetailsKey = (projectId: string, variableId: string) => `project-${projectId}-variable-${variableId}`;

export const useGetVariables = (projectId: string) => {
    const queryKey = getVariableListKey(projectId);
    return useQuery<Variable[], ExceptionResponse>(queryKey, (): Promise<Variable[]> => {
        return callGetVariables(projectId).then((data) =>
            Promise.resolve(data.map(v => mapVariable(v)))
        );
    });
};

export const useAddVariableMutation = (projectId: string) => {
    const queryClient = useQueryClient();
    const {increment} = useUpdateProjectComponents(projectId);
    const addVariable = (variable: AddVariableInput) => {
        return callAddVariable(projectId, variable)
            .then((data) => Promise.resolve(mapVariable(data)))
    };
    return useMutation<Variable, InputValidationExceptionResponse, AddVariableInput>(addVariable, {
        onSuccess: () => {
            queryClient.invalidateQueries(getVariableListKey(projectId));
            increment(ProjectComponentType.VARIABLE);
        },
    });
};

export const useUpdateVariableMutation = (projectId: string) => {
    const queryClient = useQueryClient();
    const updateVariable = (variable: UpdateVariableInput) => {
        return callUpdateVariable(projectId, variable.id, variable)
            .then((data) => Promise.resolve(mapVariable(data)))
    };
    return useMutation<Variable, InputValidationExceptionResponse, UpdateVariableInput>(updateVariable, {
        onSuccess: (data) => {
            queryClient.setQueriesData<Variable[] | undefined>(getVariableListKey(projectId), (prev = []) => prev.map(it => it.id === data.id ? data : it));
            queryClient.setQueriesData(getVariableDetailsKey(projectId, data.id), () => data);
        },
    });
};

export const useDeleteVariableMutation = (projectId: string) => {
    const queryClient = useQueryClient();
    const {decrement} = useUpdateProjectComponents(projectId);
    const deleteVariable = (variableId: string) => {
        return callDeleteVariable(projectId, variableId)
            .then((_) => Promise.resolve());
    };
    return useMutation<void, ExceptionResponse, string>(deleteVariable, {
        onSuccess: () => {
            queryClient.invalidateQueries(getVariableListKey(projectId));
            decrement(ProjectComponentType.VARIABLE)
        },
    });
};

export const isVariableNameValid = (v: string) => !v.match(/^\d/) && ["!", "#", ".", ",", "^", "/", "\\", "\n", "\r", "\t", "-", "+", "*", " "].find(it => v.includes(it)) === undefined;
export const isFileNameValid = (v: string) => ["/", "\\", "\n", "\r", "\t", " "].find(it => v.includes(it)) === undefined;
export const isVariableNameOrFileNameValid = (v: string, fileType: DataSourceFileType): boolean => {
    if ([DataSourceFileType.ANY, DataSourceFileType.ZIP].includes(fileType)) {
        return isFileNameValid(v);
    }
    return isVariableNameValid(v);
};
