import "babel-polyfill";
import {Suspense, useMemo, useState} from "react";
import {render} from "react-dom";
import {QueryClient, QueryClientProvider} from "react-query";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import {styled, ThemeProvider} from "@mui/material";

import "./index.css";
import {lazy} from "react";
import {initKeycloak} from "./security/keycloakConfig";
import i18n from "./i18n";
import {StartLoadingScreen} from "./app/components/StartLoadingScreen/StartLoadingScreen";
import {MainLayout} from "./app/components/common/mui/mainLayout/MainLayout";
import {OrganisationPage} from "./app/pages/OrganisationPage";
import {RunnersPage} from "./app/pages/RunnersPage";
import {createMainTheme} from "./app/mui-styles/muiStyles";
import {ProjectOverviewPage} from "./app/pages/ProjectOverviewPage";
import {HelpTextContextProvider} from "./helpTextFramework";
import {I18nextProvider, useTranslation} from "react-i18next";
import {getMainLanguage} from "./app/util/i18n/i18n";
import {ColorModeProvider, getModeOfLocalStorage, setModeOfLocalStorage} from "./app/mui-styles/darkmodeProvider";
import {SimpleFormInputPage} from "./app/pages/SimpleFormInputPage";
import {initAxiosErrorHandling} from "./app/rest/restUtils";

initKeycloak();
initAxiosErrorHandling();
const queryClient = new QueryClient();

const ProjectDetailsPage = lazy(() => import("./app/pages/projectDetailsPage/ProjectDetailsPage"))

const ContentZone = styled("div")({
    display: "flex",
    flex: 1,
    flexDirection: "column",
})

const InnerI18nComponents = () => {
    const {i18n: i18nHook} = useTranslation();
    return (
        <HelpTextContextProvider defaultLng={getMainLanguage(i18nHook.language)}>
            <MainLayout>
                <ContentZone>
                    <Router>
                        <Switch>
                            <Route path="/projects/:uuid" component={ProjectDetailsPage}/>
                            <Route path="/organisation" component={OrganisationPage}/>
                            <Route path="/runners" component={RunnersPage}/>
                            <Route path="/simpleForm/:code" component={SimpleFormInputPage}/>
                            <Route path="/" component={ProjectOverviewPage}/>
                        </Switch>
                    </Router>
                </ContentZone>
            </MainLayout>
        </HelpTextContextProvider>
    );
};

const OuterApp = () => {
    const [mode, setMode] = useState<"light" | "dark">(getModeOfLocalStorage());
    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setModeOfLocalStorage(mode === "light" ? "dark" : "light")
                setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
            },
        }),
        [],
    );

    const theme = useMemo(
        () =>
            createMainTheme(mode),
        [mode],
    );
    return (
        <Suspense fallback={<StartLoadingScreen/>}>
            <QueryClientProvider client={queryClient}>
                <ColorModeProvider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <I18nextProvider i18n={i18n}>
                        <InnerI18nComponents/>
                    </I18nextProvider>
                </ThemeProvider>
                </ColorModeProvider>
            </QueryClientProvider>
        </Suspense>
    )
}

render(
    <OuterApp/>,
    document.getElementById("root"),
);

