import {useState} from "react";
import {Alert, DialogContentText, Typography} from "@mui/material";
import {useOrganisationModuleTranslation} from "../../../../util/i18n/useModuleTranslation";
import {User, useResetCredentialsMutation} from "../../../../rest/user/userQueries";
import {FooterActionProps, SimpleDialog} from "../../../common/mui/dialog/SimpleDialog";
import {Bold} from "../../../common/mui/typography/Typography";
import {ExceptionResponse} from "../../../../rest/exceptions";

const useT = () => useOrganisationModuleTranslation("userList.resetCredentialsModal");

interface ResetCredentialModalProps {
    readonly user: User;
    readonly onClose: () => void;
}

export const ResetCredentialsModal = (props: ResetCredentialModalProps) => {
    const {onClose, user} = props;
    const {t} = useT();

    const mutation = useResetCredentialsMutation(user.id);

    const [initialPassword, setInitialPassword] = useState<string>();

    const onSubmit = () => {
        mutation.mutateAsync().then(r => {
            setInitialPassword(r.password);
            setStep(1);
        });
    };

    const [step, setStep] = useState<number>(0);

    const footerActionProps: FooterActionProps = step === 0
        ? {
            cancelButton: {text: t("cancel"), onClick: onClose},
            submitButton: {text: t("submit"), onClick: onSubmit}
        }
        : {
            submitButton: {text: t("close"), onClick: onClose}
        }

    return (
        <SimpleDialog
            open={true}
            onClose={onClose}
            aria-labelledby="reset credentials"
            title={t("title", {username: user.username})}
            footerActionProps={footerActionProps}
        >
            {
                step === 0
                    ? <SubmitActionText user={user}/>
                    : <Feedback username={user.username}
                                errorResponse={mutation.error}
                                initialPassword={initialPassword}/>
            }
        </SimpleDialog>
    );
};

interface CreateUserFeedbackProps {
    readonly username: string;
    readonly initialPassword: string;
    readonly errorResponse?: ExceptionResponse;
}

const Feedback = (props: CreateUserFeedbackProps) => {
    const {username, initialPassword, errorResponse} = props;
    const {t} = useT();

    if (errorResponse) {
        return (
            <Alert severity="error">{t("feedback.error." + errorResponse.translationCode)}</Alert>
        );
    }

    return (
        <DialogContentText component="div">
            <Typography>{t("feedback.success")}</Typography>
            <Typography>{t("feedback.noEmailService")}</Typography>
            <Typography>{t("feedback.username")}: <Bold component="span">{username}</Bold></Typography>
            <Typography>{t("feedback.password")}: <Bold component="span">{initialPassword}</Bold></Typography>
            <Typography>{t("feedback.passwordChangeRequired")}</Typography>
        </DialogContentText>
    );
};

interface SubmitActionTextProps {
    readonly user: User;
}

const SubmitActionText = (props: SubmitActionTextProps) => {
    const {user} = props;
    const {t} = useT();
    return (
        <DialogContentText>
            {t("resetPassword", {firstName: user.firstName, surname: user.surname})}
        </DialogContentText>
    );
};
