import {useQuery} from "react-query";
import configuredAxios from "./configuredAxios";
import {ExceptionResponse} from "./exceptions";

export interface AppInfo {
    readonly version: string;
}

export const useGetAppInfo = () => {
    const queryKey = "app-info";
    return useQuery<AppInfo, ExceptionResponse>(queryKey, (): Promise<AppInfo> => {
        return configuredAxios.get<AppInfo>("/appInfo").then((data) => {
            return Promise.resolve(data);
        });
    });
};
