import {InputLabel, MenuItem, Select, Theme, useTheme} from "@mui/material";
import FormControl from "@mui/material/FormControl/FormControl";
import {OverridableStringUnion} from "@mui/types";
import {InputBasePropsSizeOverrides} from "@mui/material/InputBase/InputBase";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name: any, value: any, theme: Theme) {
    return {
        fontWeight:
            name === value
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export type SelectOption<T> = {
    readonly value: T;
    readonly label?: string;
    readonly disabled?: boolean;
}

export interface SelectProps<T extends any> {
    readonly labelId?: any;
    readonly value: T;
    readonly label?: string;
    readonly options: SelectOption<T>[];
    readonly size?: OverridableStringUnion<"small" | "medium", InputBasePropsSizeOverrides>
    readonly onChange: (value: T) => void;
    readonly disabled?: boolean;
}

export function SingleSelect<T>(props: SelectProps<T>) {
    const {labelId, value, options, onChange, disabled, label, size} = props;
    const theme = useTheme();
    const optionByValue = (value: T): SelectOption<T> => options.find(o => o.value === value);

    return (
        <FormControl>
            {
                label && <InputLabel id={labelId}>{label}</InputLabel>
            }
        <Select
            variant={"outlined"}
            size={size || "small"}
            label={label}
            labelId={labelId}
            disabled={disabled}
            value={value}
            onChange={(e: any) => {
                e.stopPropagation();
                e.preventDefault();
                onChange(e.target.value);

            }}
            renderValue={(selected: any) => (
                getLabel(optionByValue(selected))
            )}
            MenuProps={MenuProps}
        >
            {options.map((option, index) => (
                <MenuItem key={index} value={option.value as unknown as string} disabled={option.disabled}
                          style={getStyles(option.value, value, theme)}>
                    {getLabel(option)}
                </MenuItem>
            ))}
        </Select>
        </FormControl>
    );
}

const getLabel = (option: SelectOption<any>): string => {
    const {label, value} = option;
    return label ?? value;
};
