import {
    CreateDataSource,
    DataSource,
    DataSourceFileType,
    DataSourceTestParams,
    DataSourceUrlType,
    DataSourceZipTestResponseEntry
} from "./dataSourceQueries";
import {AuthMethod, CSVSettings, HttpMethod} from "../commonTypes";
import configuredAxios from "../configuredAxios";

export interface DataSourceResponse {
    readonly id: string;
    readonly name: string;
    readonly variableName: string;
    readonly url: string;
    readonly urlType: DataSourceUrlType;
    readonly method: HttpMethod;
    readonly auth: {
        method: AuthMethod,
        username?: string,
        password?: string,
        tokenUrl?: string;
        clientId?: string;
        clientSecret?: string;
    };
    readonly fileType: DataSourceFileType;
    readonly csvSettings?: CSVSettings;
    readonly createdAt: string;
    readonly createdBy: string;
    readonly updatedAt: string;
    readonly updatedBy: string;
}

export interface DataSourceTestResponse {
    readonly url: string;
    readonly dateTime: string;
    readonly success: boolean;
    readonly result?: any | any[][] | DataSourceZipTestResponseEntry[];
    readonly resultRaw?: string;
    readonly error?: {
        message: string;
        translationCode: string;
        url?: string;
        method?: HttpMethod;
        reason: string;
    };
}

export const callGetDataSources = (projectId: string) => configuredAxios.get<DataSourceResponse[]>(uri(projectId));
export const callPostDataSource = (projectId: string, dataSource: CreateDataSource) => configuredAxios.post<DataSourceResponse>(uri(projectId), dataSource);
export const callPutDataSource = (projectId: string, dataSource: DataSource) => configuredAxios.put<DataSourceResponse>(uri(projectId, dataSource.id), dataSource);
export const callDeleteDateSource = (projectId: string, dataSourceId: string) => configuredAxios.delete(uri(projectId, dataSourceId));
export const callTestDataSource = (projectId: string, params: DataSourceTestParams) => configuredAxios.post<DataSourceTestResponse>(`${uri(projectId)}/test`, params);

const uri = (projectId: string, dataSourceId?: string) => {
    const paths = ["projects", projectId, "dataSources", dataSourceId].filter(it => !!it)
    return `/${paths.join("/")}`
}
