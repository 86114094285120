import {createTheme, PaletteMode} from "@mui/material";

declare module "@mui/material/styles/createTheme" {
    interface Theme {
        rDataCenter: {
            color: React.CSSProperties["color"]
            backgroundColor: React.CSSProperties["backgroundColor"]
        }
    }

    // allow configuration using `createTheme`
    // noinspection JSUnusedGlobalSymbols
    interface ThemeOptions {
        rDataCenter?: {
            color: React.CSSProperties["color"]
            backgroundColor: React.CSSProperties["backgroundColor"]
        }
    }
}

export const DARK_MODE_BACKGROUND_COLOR = "#111";
export const LIGHT_MODE_BACKGROUND_COLOR = "#eee";
export const R_DATACENTER_BACKGROUND_COLOR = "#4f0200";
export const R_DATACENTER_MAIN_COLOR = "#eee"

export const createMainTheme = (mode: PaletteMode) => createTheme({
    palette: {
        // type: "light",
        mode: mode,
        primary: {
            main: "#1890ff",
            light: "#1890ff",
        },
        secondary: {
            main: "#f50057",
        },
    },
    typography: {
        fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
        body1: {
        },
        h1: {
            fontSize: "2rem"
        },
        h2: {
            fontSize: "2rem"
        },
        h3: {
            fontSize: "1.5rem"
        },
        h4: {
            fontSize: "1.25rem"
        },
        h5: {
            fontSize: "1rem"
        }
    },
    rDataCenter: {
        backgroundColor: R_DATACENTER_BACKGROUND_COLOR,
        color: R_DATACENTER_MAIN_COLOR
    }
});
