import {useEffect, useState} from "react";
import {StartLoadingScreen} from "../../../StartLoadingScreen/StartLoadingScreen";

import {keycloak} from "../../../../../security/keycloakConfig";
import {styled} from "@mui/material";
export interface MainLayoutProps {
    readonly children: any;
}


const StyledDiv = styled("div")({
    width: "100%",
    height: "100%",
    alignSelf: "stretch",
    display: "flex",
    alignItems: "stretch",
    alignContent: "stretch",
    justifyContent: "stretch",
});

export const MainLayout = (props: MainLayoutProps) => {
    const [isAuthenticated, setAuthenticated] = useState<boolean>(keycloak?.authenticated ?? false);

    useEffect(() => {
        if (!isAuthenticated) {
            setTimeout(() => setAuthenticated(keycloak?.authenticated ?? false), 500); // FIXME: find a better way
        }
    }, [isAuthenticated]);
    return (
        <StyledDiv>
            {
                isAuthenticated
                    ? props.children
                    : (
                        <StartLoadingScreen/>
                    )
            }
        </StyledDiv>
    );
};
