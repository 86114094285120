import {SimpleFormManagementView, SimpleFormTemplate, SubmitSimpleFormResponse} from "./simpleFormQueries";
import configuredAxios from "../configuredAxios";

export interface UpdateSimpleFormManagementBody {
    readonly enabled: boolean;
}

export const callSimpleFormTemplate = (code: string) => configuredAxios.get<SimpleFormTemplate>(`/simpleForm/${code}`);
export const callSubmitSimpleFormTemplate = (code: string, variables: { [key: string]: string }) => configuredAxios.post<SubmitSimpleFormResponse>(`/simpleForm/${code}`, {variables});

export const callSimpleFormManagement = (projectId: string) => configuredAxios.get<SimpleFormManagementView>(projectUri(projectId));
export const callCreateSimpleFormManagement = (projectId: string) => configuredAxios.post<SimpleFormManagementView>(projectUri(projectId));
export const callUpdateSimpleFormManagement = (projectId: string, simpleFormId: string, data: UpdateSimpleFormManagementBody) => configuredAxios.put<SimpleFormManagementView>(projectUri(projectId, simpleFormId), data);

const projectUri = (projectId: string, simpleFormId?: string) => {
    const paths = ["projects", projectId, "simpleForm", simpleFormId].filter(it => !!it)
    return `/${paths.join("/")}`
}
