import {CircularProgress, Typography} from "@mui/material";

export interface LoadingProps {
    readonly loadingText?: string;
}

export const Loading = (props: LoadingProps) => {
    const {loadingText} = props;
    return (
        <Typography component={"span"}
                    sx={{
                        width: "100%",
                        margin: "10px 0",
                        padding: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center"
                    }}
        >
            <CircularProgress/>
            <Typography component={"span"} sx={{
                ml: "10px",
                display: "flex",
                alignItems: "center",
                alignContent: "center"
            }}>{loadingText}</Typography>
        </Typography>
    );
};
