import {SimpleInfo, SimpleInfoPaper} from "./SimpleInfoPaper";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export interface ErrorPaperProps {
    readonly label?: string;
}

export const ErrorPaper = (props: ErrorPaperProps) => {
    const {label} = props;
    return (
        <SimpleInfoPaper
            icon={<ErrorOutlineIcon fontSize="large"/>}
            label={label}
        />
    );
};

export const ErrorInfo = (props: ErrorPaperProps) => {
    const {label} = props;
    return (
        <SimpleInfo
            icon={<ErrorOutlineIcon fontSize="large"/>}
            label={label}
        />
    );
};

