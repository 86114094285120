import {useQuery} from "react-query";
import configuredAxios from "./configuredAxios";
import {ExceptionResponse} from "./exceptions";

export interface Runner {
    readonly id: string;
    readonly name: string;
    readonly description: string;
    readonly tags: string[];
    readonly numberOfCores: number;
    readonly amountOfMemory: number;
    readonly version: string;
    readonly rversion: string;
    readonly createdAt: string;
}

export const useGetRunners = () => {
    return useQuery<Runner[], ExceptionResponse>("runners", (): Promise<Runner[]> => {
        return configuredAxios.get<Runner[]>("/runners")
    })
};
export const useGetSecret = () => {
    return useQuery<string, ExceptionResponse>("runners-secret", (): Promise<string> => {
        return configuredAxios.get<{ secret: string }>("/runners/secret").then((data) => {
            return Promise.resolve(data.secret);
        });
    });
};
