import {useState} from "react";
import {DialogContentText, Typography} from "@mui/material";
import {useOrganisationModuleTranslation} from "../../../../util/i18n/useModuleTranslation";
import {ErrorAlert} from "./ErrorAlert";
import {StremoTextField} from "../../../common/mui/inputs/StremoTextField";
import {FormLine} from "../../../common/mui/form/FormLine";
import {SMALL_DIALOG_WIDTH} from "../../../../state/constants";
import {FooterActionProps, SimpleDialog} from "../../../common/mui/dialog/SimpleDialog";
import {mapRole, useEditUserForm, UserRole} from "./editUserForm";
import {SingleSelect} from "../../../common/mui/inputs/SingleSelect";
import {useAddUserMutation} from "../../../../rest/user/userQueries";
import {Bold} from "../../../common/mui/typography/Typography";

interface AddUserModalProps {
    readonly groupName: string;
    readonly onClose: () => void;
}

export const AddUserModal = (props: AddUserModalProps) => {
    const {onClose, groupName} = props;
    const {t} = useOrganisationModuleTranslation("userList.addModal");
    const methods = useEditUserForm();
    const [initialPassword, setInitialPassword] = useState<string>("");
    const [usernameResponse, setUsernameResponse] = useState<string>("");

    const addUserMutation = useAddUserMutation(groupName);
    const onSubmit = () => {
        const values = methods.submit();
        addUserMutation.mutateAsync({
            email: values.email,
            firstName: values.firstName,
            groupPath: props.groupName,
            role: mapRole(values.role),
            surname: values.surname,
            username: values.username,
        })
            .then(r => {
                setInitialPassword(r.initialPassword);
                setUsernameResponse(r.username)
                setStep(1)
            })
    };

    const [step, setStep] = useState<number>(0);

    const footerAttributes: FooterActionProps = step === 0
        ? {
            cancelButton: {text: t("cancel"), onClick: onClose},
            submitButton: {text: t("submit"), onClick: onSubmit, disabled: !methods.isValid()}
        }
        : {submitButton: {text: t("close"), onClick: onClose}}

    return (
        <SimpleDialog
            title={t("title", {groupName})}
            htmlMinWidth={SMALL_DIALOG_WIDTH}
            open={true}
            onClose={onClose}
            aria-labelledby="add-user-to-organisation"
            footerActionProps={footerAttributes}
        >
            {
                step === 0
                    ? (
                        <>
                            {
                                addUserMutation.isError && (
                                    <ErrorAlert error={addUserMutation.error} translationBaseKey={"userList.addModal"}/>
                                )
                            }
                            <FormLine
                                label={t("fields.email.label")}
                                required={false}
                                isDirty={methods.dirty.email}
                                hasError={!!methods.errors.email}
                                errorMessage={methods.errors.email && t(`fields.email.validation.${methods.errors.email}`)}
                            >
                                <StremoTextField
                                    autoFocus={true}
                                    type="email"
                                    value={methods.email}
                                    onChange={e => methods.on.emailChange(e.target.value)}
                                />

                            </FormLine>
                            <FormLine
                                isDirty={methods.dirty.username}
                                hasError={!!methods.errors.username}
                                errorMessage={methods.errors.username && t(`fields.username.validation.${methods.errors.username}`)}
                                label={t("fields.username.label")}>
                                <StremoTextField
                                    required={true}
                                    value={methods.username}
                                    onChange={e => methods.on.usernameChange(e.target.value)}
                                />
                            </FormLine>
                            <FormLine
                                label={t("fields.firstName.label")}
                                isDirty={methods.dirty.firstName}
                                hasError={!!methods.errors.firstName}
                                errorMessage={methods.errors.firstName && t(`fields.firstName.validation.${methods.errors.firstName}`)}
                            >
                                <StremoTextField
                                    required={true}
                                    value={methods.firstName}
                                    onChange={(e) => methods.on.firstNameChange(e.target.value)}
                                />
                            </FormLine>
                            <FormLine
                                label={t("fields.surname.label")}
                                isDirty={methods.dirty.surname}
                                hasError={!!methods.errors.surname}
                                errorMessage={methods.errors.surname && t(`fields.surname.validation.${methods.errors.surname}`)}
                            >

                                <StremoTextField
                                    required={true}
                                    fullWidth={true}
                                    value={methods.surname}
                                    onChange={e => methods.on.surnameChange(e.target.value)}/>
                            </FormLine>

                            <FormLine
                                label={t("fields.roles.label")}
                                hasError={!!methods.errors.role}
                            >
                                <SingleSelect<UserRole>
                                    value={methods.role}
                                    options={[
                                        {
                                            value: UserRole.ADMIN,
                                            label: "Admin"
                                        },
                                        {
                                            value: UserRole.DEVELOPER,
                                            label: "Developer",
                                        },
                                        {
                                            value: UserRole.VIEWER,
                                            label: "Viewer"
                                        }
                                    ]}
                                    onChange={methods.on.roleChange}
                                />
                            </FormLine>
                        </>

                    )
                    : <CreateUserFeedback username={usernameResponse}
                                          initialPassword={initialPassword}/>
            }
        </SimpleDialog>
    );
};

interface CreateUserFeedbackProps {
    readonly username: string;
    readonly initialPassword: string;
}

const CreateUserFeedback = (props: CreateUserFeedbackProps) => {
    const {username, initialPassword} = props;
    const {t} = useOrganisationModuleTranslation("userList.addModal.feedback");
    return (
        <DialogContentText component="div">
            <Typography>{t("success")}</Typography>
            <Typography>{t("noEmailService")}</Typography>
            <Typography>{t("username")}: <Bold component="span">{username}</Bold></Typography>
            <Typography>{t("password")}: <Bold component="span">{initialPassword}</Bold></Typography>
            <Typography>{t("passwordChangeRequired")}</Typography>
        </DialogContentText>
    );
};
