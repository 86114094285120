import {useGetSecret} from "../../../rest/runnerQueries";
import {Typography} from "@mui/material";
import {useRunnerModuleTranslation} from "../../../util/i18n/useModuleTranslation";
import {SimplePaper} from "../../common/mui/paper/SimplePaper";
import {LabelValuePair} from "../../common/mui/section/LabelValuePair";
import {Loading} from "../../common/mui/loading/Loading";

export const SecretView = () => {
    const {t} = useRunnerModuleTranslation("secretKey");
    const {data, isLoading, isError, isSuccess} = useGetSecret();

    return (
        <SimplePaper title={t("heading")}>
            {
                isLoading && <Loading loadingText={t("loading")}/>
            }
            {
                isError && <Typography> {t("error")}</Typography>
            }
            {
                isSuccess && <LabelValuePair label={t("label")} value={data} smv={12} sml={12}/>
            }

        </SimplePaper>
    );
};
