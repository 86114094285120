import {NullableToolTip} from "./private/NullableToolTip";
import {IconButton} from "@mui/material";
import {IconProps} from "./index";
import {ReactNode} from "react";

type ClickableIconProps = IconProps & {children: ReactNode}

export const ClickableIcon = (props: ClickableIconProps) => {
    const {children, onClick, toolTip, color} = props;
    return (
        <NullableToolTip tooltip={toolTip}>
            <IconButton aria-label={props["props"]} onClick={onClick} style={{color}}>
                {children}
            </IconButton>
        </NullableToolTip>
    );
};
