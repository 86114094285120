import {FormHelperText, InputLabel, MenuItem, Select as MuiSelect, Theme, useTheme} from "@mui/material";
import {ChangeEvent} from "react";
import {useController, UseControllerProps} from "react-hook-form";
import {UIElementProps} from "../UIElementProps";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name: string, value: string, theme: Theme) {
    return {
        fontWeight:
            name === value
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export type SelectOption = {
    readonly value: any;
    readonly label?: string;
}

export interface SelectProps {
    readonly id: string;
    readonly options: SelectOption[];
}

export function SingleSelect<FormType = any>(props: UseControllerProps<FormType> & UIElementProps & SelectProps) {
    const {label, options, id, required, error, helperText} = props;
    const theme = useTheme();
    const {field} = useController(props);
    const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
        field.onChange(event);
    };

    const optionByValue = (value: string): SelectOption => {
        return options.find(o => o.value === value);
    }
// FIXME

    return (
        <>
            <InputLabel id={`${id}-label`} required={required} error={error}>{label}</InputLabel>
            <MuiSelect
                size={"small"}
                {...field}
                inputRef={field.ref}
                labelId={`${id}-label`}
                label={label}
                multiple={false}
                value={field.value || ""}
                onChange={handleChange as any}
                renderValue={(selected) => (
                    getLabel(optionByValue(selected as string))
                )}
                MenuProps={MenuProps}
            >
                {options.map((option, index) => (
                    <MenuItem key={index} value={option.value}
                              style={getStyles(option.value, field.value as string, theme)}>
                        {getLabel(option)}
                    </MenuItem>
                ))}
            </MuiSelect>
            {error && (
                <FormHelperText error={error} required={required}>{helperText}</FormHelperText>
            )}
        </>
    );
}

const getLabel = (option: SelectOption): string => {
    const {label, value} = option;
    return label ?? value;
}
