import {
    getLinkToCalculation,
    SimpleFormTemplate,
    useSubmitSimpleFormTemplateMutation
} from "../../rest/simpleForm/simpleFormQueries";
import {H1} from "../common/mui/headings/Headings";
import {Alert, Box, Button, Typography} from "@mui/material";
import {useSimpleFormModuleTranslation} from "../../util/i18n/useModuleTranslation";
import {FormProvider, useForm} from "react-hook-form";
import {StremoFormControl} from "../common/mui/form/StremoFormControl";
import {VariableValueInput} from "../common/mui/inputs/VariableValueInput";
import {VariableType} from "../../rest/variables/variableQueries";

interface SimpleFormInputProps {
    readonly form: SimpleFormTemplate;
    readonly code: string;
}

export const SimpleFormInput = (props: SimpleFormInputProps) => {
    const {form, code} = props;
    const {title, description, variables} = form;
    const {t} = useSimpleFormModuleTranslation("inputForm.form");
    const submitMutation = useSubmitSimpleFormTemplateMutation(code);

    let defaultValues = {}
    variables.forEach(it => defaultValues[it.name] = it.type === VariableType.SECRET ? "" : it.defaultValue)

    const methods = useForm<{ [key: string]: string }>({
        mode: "onSubmit",
        defaultValues: defaultValues,
    });
    const {handleSubmit} = methods

    const submit = (data) => {
        console.log("submit", data);
        submitMutation.mutate(data)
    }
    return (
        <Box sx={{color: "text.primary"}}>
            <H1>{title}</H1>
            {
                description && <Typography>{form.description}</Typography>
            }
            <Box sx={{mt: 2, mb: 2}}>
                {
                    submitMutation.isError && (
                        <Alert severity="error" sx={{mb: 2}}>
                            {t("error")}
                        </Alert>
                    )
                }

                {
                    submitMutation.isSuccess
                        ? (
                            <div>
                                <Alert severity="success">
                                    <Typography>{t("success")}</Typography>
                                    {submitMutation.data && (
                                        <Typography component="a"
                                                    href={getLinkToCalculation(submitMutation.data.projectId, submitMutation.data.calculationId)}>
                                            {t("link")}
                                        </Typography>
                                    )
                                    }
                                </Alert>
                            </div>
                        )
                        : (
                            <FormProvider {...methods}>
                                <form onSubmit={handleSubmit(submit)}>
                                    {
                                        variables.length === 0 && (
                                            <StremoFormControl>
                                                <Typography>{t("noVariables")}</Typography>
                                            </StremoFormControl>
                                        )
                                    }
                                    {
                                        variables.map(it => {
                                            return (
                                                <StremoFormControl key={it.name}>
                                                    <VariableValueInput name={it.name} label={it.name}
                                                                        variableType={it.type}/>
                                                </StremoFormControl>
                                            )

                                        })
                                    }
                                    <Button type="submit" color="primary" variant="contained">{t("submit")}</Button>
                                </form>
                            </FormProvider>
                        )
                }
            </Box>
        </Box>
    );
};
