import {Namespace, useTranslation, UseTranslationOptions, UseTranslationResponse} from "react-i18next";

export const useModuleTranslation = (key?: string, ns?: Namespace, options?: UseTranslationOptions): UseTranslationResponse<Namespace> => {
    const module = getModule(key);
    const tr = useTranslation(ns, options);
    return {
        ...tr,
        t: (k: string, translationOptions?: any) => tr.t(module + k, translationOptions),
        ready: tr.ready,
    };
};

export const useOrganisationModuleTranslation = (key?: string, options?: UseTranslationOptions): UseTranslationResponse<Namespace> => {
    return useModuleTranslation(key, "organisation", options);
};
export const useRunnerModuleTranslation = (key?: string, options?: UseTranslationOptions): UseTranslationResponse<Namespace> => {
    return useModuleTranslation(key, "runner", options);
};
export const useOverviewModuleTranslation = (key?: string, options?: UseTranslationOptions): UseTranslationResponse<Namespace> => {
    return useModuleTranslation(key, "overview", options);
};
export const useProjectModuleTranslation = (key?: string, options?: UseTranslationOptions): UseTranslationResponse<Namespace> => {
    return useModuleTranslation(key, "project/translation", options);
};
export const useProjectDashboardModuleTranslation = (key?: string, options?: UseTranslationOptions): UseTranslationResponse<Namespace> => {
    return useModuleTranslation(key, "project/dashboard", options);
};
export const useSchedulingModuleTranslation = (key?: string, options?: UseTranslationOptions): UseTranslationResponse<Namespace> => {
    return useModuleTranslation(key, "project/scheduling", options);
};
export const useOutputChannelModuleTranslation = (key?: string, options?: UseTranslationOptions): UseTranslationResponse<Namespace> => {
    return useModuleTranslation(key, "project/outputChannel", options);
};
export const useVariableModuleTranslation = (key?: string, options?: UseTranslationOptions): UseTranslationResponse<Namespace> => {
    return useModuleTranslation(key, "project/variables", options);
};
export const useCalculationModuleTranslation = (key?: string, options?: UseTranslationOptions): UseTranslationResponse<Namespace> => {
    return useModuleTranslation(key, "project/calculation", options);
};
export const useDataSourceModuleTranslation = (key?: string, options?: UseTranslationOptions): UseTranslationResponse<Namespace> => {
    return useModuleTranslation(key, "project/dataSource", options);
};
export const useFileModuleTranslation = (key?: string, options?: UseTranslationOptions): UseTranslationResponse<Namespace> => {
    return useModuleTranslation(key, "project/files", options);
};
export const useSimpleFormModuleTranslation = (key?: string, options?: UseTranslationOptions): UseTranslationResponse<Namespace> => {
    return useModuleTranslation(key, "project/simpleForm", options);
};

const getModule = (key?: string) => {
    if (key === undefined || key.trim().length === 0) {
        return "";
    }
    return key.endsWith(".") ? key : `${key}.`;
};
