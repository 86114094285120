import {AxiosError} from "axios";
import {InputValidationExceptionResponse, toInputValidationException} from "../exceptions";
import {User} from "./userQueries";
import {UserResponse} from "./userRestCalls";

export enum Field {
    username = "username",
    email = "email"
}

export const mapEditUserErrorResponse = (error: AxiosError): InputValidationExceptionResponse => {
    return toInputValidationException(error.response.data, error.response.status)
};
export const mapUserResponse = (data: UserResponse): User => {
    return {
        id: data.id,
        email: data.email,
        fullName: data.firstName + " " + data.lastName,
        firstName: data.firstName,
        surname: data.lastName,
        username: data.username,
        roles: data.roles,
    };
};
