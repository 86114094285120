import {ComplexHelpTextItem, Namespace, useHelpTextContext} from "./helpTextContext";
import {useEffect, useState} from "react";
import _ from "lodash";

export interface HelpTextOutputObject {
    title?: string;
    content?: string;
}

export type HelpTextOutput = string | HelpTextOutputObject

export interface UseHelp {
    readonly lng: string;
    readonly loading: boolean;
    readonly h: (key: string, emptyText: string) => HelpTextOutput;
}


export const useHelp = (namespace?: string): UseHelp => {
    const context = useHelpTextContext();
    const [translations, setTranslations] = useState<Namespace>();

    useEffect(() => {
        context.getNamespaceContent(namespace).then(setTranslations);
    });

    const h = (key: string, emptyText?: string): HelpTextOutput => {
        const help = _.at(translations, key)[0];
        if(!!help) {
            if(typeof help === "string"){
                return help;
            }
            if(Object.keys(help).every(it => ["title", "content"].includes(it)) ){
                const helpObject = help as unknown as ComplexHelpTextItem;
                return {
                    title: helpObject.title,
                    content: _.isArray(helpObject.content) ? helpObject.content.join(""): helpObject.content,
                };
            }
            return `key is an object: [${Object.keys(help).join(", ")}]`;
        }
        return  emptyText || key;
    };

    return {
        lng: context.getLanguage(),
        loading: context.isNamespaceLoading(namespace),
        h,
    };
};
