import {VariableType} from "../../../../rest/variables/variableQueries";
import {SingleSelect} from "../hook-form/SingleSelect";
import {TextInput} from "../hook-form/TextInput";

export interface VariableValueInputProps {
    readonly name: string;
    readonly label: string;
    readonly variableType: VariableType;
}

export const VariableValueInput = (props: VariableValueInputProps) => {
    const {label, variableType, name} = props;

    if (variableType === VariableType.BOOLEAN) {
        return (
            <SingleSelect
                name={name}
                label={label}
                id={`start-calculation-variable-value-${name}`}
                options={[
                    {
                        value: true,
                        label: "true"
                    },
                    {
                        value: false,
                        label: "false"
                    }
                ]}/>
        );
    }

    const getTypeString = (): string | undefined => {
        switch (variableType) {
            case VariableType.DECIMAL:
                return "number"
            case VariableType.NUMBER:
                return "number"
            case VariableType.SECRET:
                return "password"
        }
    }

    return (
        <TextInput
            name={name}
            label={label}
            autoComplete="off"
            type={getTypeString()}
            step={variableType === VariableType.NUMBER ? "" : "any"}
        />
    );
};
