import {IconProps} from "./index";
import EditIcon from "@mui/icons-material/Edit";
import {ClickableIcon} from "./ClickableIcon";

export const EditIconButton = (props: IconProps) => {
    return (
        <ClickableIcon {...props}>
            <EditIcon/>
        </ClickableIcon>
    );
}
