import {Loading} from "../loading/Loading";
import {SimplePaper} from "./SimplePaper";

export interface LoadingCardProps {
    readonly loadingText?: string;
}

export const LoadingPaper = (props: LoadingCardProps) => {
    const {loadingText} = props;
    return (
        <SimplePaper>
            <Loading loadingText={loadingText}/>
        </SimplePaper>
    );
};
