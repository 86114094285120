import {useMutation, useQuery, useQueryClient} from "react-query";
import {
    callCreateSimpleFormManagement,
    callSimpleFormManagement,
    callSimpleFormTemplate,
    callSubmitSimpleFormTemplate,
    callUpdateSimpleFormManagement
} from "./simpleFormRestCalls";
import {VariableType} from "../variables/variableQueries";
import {ExceptionResponse} from "../exceptions";

const getSimpleFormMgmtQueryKey = (projectId: string) => `simple-form-${projectId}`
const getSimpleFormTemplateQueryKey = (code: string) => `simple-form-template-${code}`

export interface SimpleFormManagementView {
    readonly id: string;
    readonly code: string;
    readonly enabled: string;
    readonly createdAt: string;
    readonly createdBy: string;
    readonly updatedAt?: string;
    readonly updatedBy?: string
}

export interface SimpleFormVariable {
    readonly name: string;
    readonly type: VariableType;
    readonly defaultValue: string;
}

export interface SimpleFormTemplate {
    readonly code: string;
    readonly title: string;
    readonly description?: string;
    readonly variables: SimpleFormVariable[];
}

export interface SubmitSimpleFormResponse {
    readonly projectId: string;
    readonly calculationId: string;
}

export const useGetSimpleFormTemplate = (code: string) => {
    const queryKey = getSimpleFormTemplateQueryKey(code);
    return useQuery<SimpleFormTemplate, ExceptionResponse>(queryKey, (): Promise<SimpleFormTemplate> => {
        return callSimpleFormTemplate(code);
    }, {
        retry: false
    });
};
export const useSubmitSimpleFormTemplateMutation = (code: string) => {
    const addSimpleForm = (variables: {[key: string]: string}) => callSubmitSimpleFormTemplate(code, variables);

    return useMutation<SubmitSimpleFormResponse, ExceptionResponse, {[key: string]: string}>(addSimpleForm);
};

export const useGetSimpleFormManagement = (projectId: string) => {
    const queryKey = getSimpleFormMgmtQueryKey(projectId);
    return useQuery<SimpleFormManagementView, ExceptionResponse>(queryKey, (): Promise<SimpleFormManagementView> => {
        return callSimpleFormManagement(projectId);
    }, {
        retry: false
    });
};

export const useCreateSimpleFormMutation = (projectId: string) => {
    const queryClient = useQueryClient();
    const addSimpleForm = () => callCreateSimpleFormManagement(projectId);

    return useMutation<SimpleFormManagementView, ExceptionResponse>(addSimpleForm, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(getSimpleFormMgmtQueryKey(projectId));
        },
    });
};
export const useChangeSimpleFormMutation = (projectId: string, formId: string) => {
    const queryClient = useQueryClient();
    const addSimpleForm = (enabled: boolean) => callUpdateSimpleFormManagement(projectId, formId, {enabled});

    return useMutation<SimpleFormManagementView, ExceptionResponse, boolean>(addSimpleForm, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(getSimpleFormMgmtQueryKey(projectId));
        },
    });
};

export const getSimpleFormUrl = (code: string) => `${window.location.origin}/simpleForm/${code}`;
export const getLinkToCalculation = (projectId: string, calculationId: string) => `${window.location.origin}/projects/${projectId}/tabs/calculation/${calculationId}`;