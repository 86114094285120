import {MouseEventHandler, ReactNode} from "react";
import {Button, Theme} from "@mui/material";
import {SxProps} from "@mui/system";

export interface CancelButtonProps {
    readonly text: ReactNode;
    readonly onClick?: MouseEventHandler<HTMLButtonElement>;
    readonly disabled?: boolean;
    readonly sx?: SxProps<Theme>;
}

export const CancelButton = (props: CancelButtonProps) => {
    const {disabled, onClick, text, sx} = props;

    return (
        <Button onClick={onClick} variant="outlined" disabled={disabled} sx={sx}>{text}</Button>
    );
};
