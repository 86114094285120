import {CSSProperties, ReactNode} from "react";
import {ListPaperFrame} from "./ListPaperFrame";
import {styled} from "@mui/material";

interface PaperListProps {
    readonly papers: ReactNode[];
    readonly minPaperWidth?: CSSProperties["minWidth"];
    readonly maxPaperWidth?: CSSProperties["maxWidth"];
}

export const PaperList = (props: PaperListProps) => {
    const {papers, minPaperWidth} = props;
    return (
        <StyledDiv>
            {
                papers.map((it, index) => (<ListPaperFrame key={index} minPaperWidth={minPaperWidth}>{it}</ListPaperFrame>))
            }
        </StyledDiv>
    );
};

const StyledDiv = styled("div")({
    display: "flex",
    flexWrap: "wrap",
    gap: "20px"
});
