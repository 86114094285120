import Keycloak from "keycloak-js";
import axios from "axios";
import {HTTP_UNAUTHORIZED, REFRESH_INTERVAL, TOKEN_STORAGE_KEY} from "./keycloakConstants";
import configuredAxios from "../app/rest/configuredAxios";

const request = new XMLHttpRequest();
request.open("GET", "/keycloakConfig", false);
request.send(null);
let keycloakConfig;
try {
    if (request.status === 200) {
        keycloakConfig = JSON.parse(request.responseText);
    } else {
        // console.error("unable to load keycloak config, status: " + request.status); //tslint:disable-line
    }
} catch (e) {
    // console.error("unable to parse keycloak config ", e); //tslint:disable-line
}

export const keycloak = keycloakConfig
    ? Keycloak(keycloakConfig)
    : undefined;

interface UserInfo {
    id: string;
    username: string;
    email: string;
    fullName: string;
}

export let userInfo: UserInfo = null;

export const initKeycloak = () => {
    if (keycloak) {

        keycloak.init({onLoad: "check-sso", checkLoginIframe: true}).success(authenticated => {
            if (keycloak.authenticated) {
                sessionStorage.setItem(TOKEN_STORAGE_KEY, keycloak.token);

                // console.log("keycloak: ", keycloak);
                const idTokenParsed = keycloak.idTokenParsed as any;

                userInfo = {
                    id: idTokenParsed.sub,
                    username: idTokenParsed.preferred_username,
                    email: idTokenParsed.email,
                    fullName: idTokenParsed.name,
                };

                setInterval(() => {
                    keycloak.updateToken(10)
                        .success((refreshed) => {
                            if (refreshed) {
                                sessionStorage.setItem(TOKEN_STORAGE_KEY, keycloak.token);
                            }
                        })
                        .error(() => keycloak.logout());
                }, REFRESH_INTERVAL);
            } else {
                keycloak.login();
            }
        })
    }

    configuredAxios.interceptors.request.use((config) => {
        const token = sessionStorage.getItem(TOKEN_STORAGE_KEY);
        if (token) {
            return {...config, headers: {...config.headers, Authorization: "Bearer " + token}};
        } else {
            console.warn("no token"); //tslint:disable-line
            return config;
        }
    });

    const onReqFailure = (error) => {
        if (error
            && error.response
            && (error.response.status === HTTP_UNAUTHORIZED)) {
            console.warn("auto logout because backend returned " + error.response.status); //tslint:disable-line
            keycloak.logout();
        }
        return Promise.reject(error);
    };
    axios.interceptors.response.use((response) => (response), onReqFailure);
};

export const hasUserRole = (role: string): boolean => {
    return keycloak?.realmAccess?.roles?.find(r => r.toLowerCase() === role) !== undefined;
};

export const isAdmin = () => hasUserRole("admin");
