import {Navbar} from "../components/common/mui/navbar/Navbar";

import {useOrganisationModuleTranslation} from "../util/i18n/useModuleTranslation";
import {MainContentArea} from "../components/common/mui/mainLayout/MainContentArea";
import {OverviewSidebar} from "../components/common/overviewSidebar/OverviewSidebar";
import {UserList} from "../components/organisation/userList/UserList";
import {keycloak} from "../../security/keycloakConfig";
import {Page} from "../components/common/mui/page/Page";

export const OrganisationPage = () => {
    const {t} = useOrganisationModuleTranslation();
    const groups: string[] = (keycloak.idTokenParsed as any)?.groups || [];
    return (
        <Page>
            <Navbar title={t("overview")}/>
            <MainContentArea sidebar={<OverviewSidebar/>}>
                {groups.map((group, index) => <UserList groupName={group} key={index}/>)}
            </MainContentArea>
        </Page>
    );
};
