import {useMutation, useQuery, useQueryClient} from "react-query";
import {mapUserResponse} from "./userMappers";
import {ExceptionResponse, InputValidationExceptionResponse} from "../exceptions";
import {
    AddUserResponse,
    callAddUser,
    callDeleteUser,
    callGetUser,
    callGetUsers,
    callResetCredentials,
    callUpdateUser,
    ResetCredentialsResponse
} from "./userRestCalls";

const getUserQueryKey = (id: string) => `user-${id}`;
const getUsersOfGroupQueryKey = (id: string) => `users-group-${id}`;

export interface User {
    readonly id: string;
    readonly username: string;
    readonly email: string;
    readonly fullName: string;
    readonly firstName: string;
    readonly surname: string;
    readonly roles: string[];
}

export const useGetUser = (id: string) => {
    return useQuery<User, ExceptionResponse>(getUserQueryKey(id), (): Promise<User> => {
        return callGetUser(id).then((data) => Promise.resolve(mapUserResponse(data)));
    });
};

export const useGetUsersOfGroup = (groupName: string) => {
    return useQuery<User[], ExceptionResponse>(getUsersOfGroupQueryKey(groupName), (): Promise<User[]> => {
        return callGetUsers(groupName).then(data => Promise.resolve(data.map(d => mapUserResponse(d))))
    });
};

export interface AddUser {
    readonly username: string;
    readonly email?: string;
    readonly firstName: string;
    readonly surname: string;
    readonly role: string;
    readonly groupPath: string;
}

export const useAddUserMutation = (groupName: string) => {
    const queryClient = useQueryClient();
    const createUser = (userInput: AddUser) => callAddUser(userInput)
    return useMutation<AddUserResponse, InputValidationExceptionResponse, AddUser>(createUser, {
        onSuccess: () => {
            queryClient.invalidateQueries(getUsersOfGroupQueryKey(groupName));
        },
    });
};

export interface EditUser {
    readonly id: string;
    readonly email?: string;
    readonly firstName: string;
    readonly surname: string;
    readonly role: string;
}

export const useUpdateUserMutation = (groupName: string, userId: string) => {
    const queryClient = useQueryClient();
    const updateUser = (userInput: EditUser) => callUpdateUser(userId, userInput)
        .then(data => Promise.resolve(mapUserResponse(data)));
    return useMutation<User, InputValidationExceptionResponse, EditUser>(updateUser, {
        onSuccess: () => {
            queryClient.invalidateQueries(getUsersOfGroupQueryKey(groupName));
        },
    });
};

export const useDeleteUserMutation = (groupName: string, userId: string) => {
    const queryClient = useQueryClient();
    const deleteUser = () => callDeleteUser(userId);
    return useMutation<void, ExceptionResponse, void>(deleteUser, {
        onSuccess: () => {
            queryClient.invalidateQueries(getUsersOfGroupQueryKey(groupName));
        },
    });
};


export const useResetCredentialsMutation = (userId: string) => {
    const resetCredentials = () => callResetCredentials(userId)
    return useMutation<ResetCredentialsResponse, ExceptionResponse, void>(resetCredentials);
};

