import _ from "lodash";
import {useHistory} from "react-router-dom";
import {ErrorAlert} from "../../common/mui/alerts/ErrorAlert";
import {Project, useGetProjectList} from "../../../rest/project/projectQueries";
import {useOverviewModuleTranslation} from "../../../util/i18n/useModuleTranslation";
import {ProjectCard} from "./projectCard/ProjectCard";
import {EmptyListPaper} from "../../common/mui/paper/EmptyListPaper";
import {PaperList} from "../../common/mui/list/PaperList";
import {LoadingPaper} from "../../common/mui/paper/LoadingPaper";

export const ProjectList = (props: { searchText: string }) => {
    const {searchText} = props;
    const {t} = useOverviewModuleTranslation("projects");
    const history = useHistory();
    const {data = [], isLoading, error} = useGetProjectList();

    const filteredProjects = (searchText?.trim().length > 0) ? data.filter(d => projectFilterFunction(searchText.trim(), d)) : data;

    if (isLoading) {
        return <LoadingPaper/>;
    }

    if (error) {
        return <ErrorAlert error={error}/>;
    }

    if (filteredProjects.length === 0) {
        return <EmptyListPaper label={t("noProjects")}/>
    }

    return (
        <PaperList papers={
            sort(filteredProjects).map((project, index) => (
                <ProjectCard project={project} key={index}
                             onClick={() => history.replace("/projects/" + project.id)}/>
            ))
        }/>
    );
};

const projectFilterFunction = (searchTerm: string, project: Project): boolean => {
    const {name, description, visibility} = project;
    return name.includes(searchTerm)
        || description?.includes(searchTerm)
        || visibility.includes(searchTerm);
};

const sort = (projects: Project[]): Project[] => {
    return _.orderBy(projects, [p => p.name.toLowerCase()], ["asc"]);
}