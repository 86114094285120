import {Avatar as MuiAvatar} from "@mui/material";
import {MouseEventHandler} from "react";

// https://mui.com/components/avatars/#main-content

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name: string) {
    return {
        sx: {
            bgcolor: stringToColor(name),
            cursor: "pointer"
        },
        children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
}

export interface AvatarProps {
    readonly name: string;
    readonly onClick?: MouseEventHandler;
}

export const Avatar = (props: AvatarProps) => <MuiAvatar {...stringAvatar(props.name)} onClick={props.onClick} variant="rounded"/>;
