import React, {ReactNode} from "react";
import {Box, Button, styled} from "@mui/material";

import {SearchInput} from "../inputs/SearchInput";
import {HelpModal} from "../help/HelpModal";
import {H2} from "../headings/Headings";

export interface TabHeaderProps {
    readonly title: string;
    readonly addButtonElement?: ReactNode;
    readonly showAddButton?: boolean;
    readonly addButtonText?: string;
    readonly onAddButtonClick?: () => void;
    readonly showSearchField?: boolean;
    readonly searchFieldPlaceholder?: string;
    readonly onSearchFieldChange?: (text: string) => void;
    readonly help?: {
        namespace?: string;
        key: string;
        emptyText?: string;
    };
}

const TabHeaderDiv = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
    color: "text.primary",
});
const SectionDiv = styled(Box)({
    display: "flex",
    color: "text.primary"
});

export const TabHeader = (props: TabHeaderProps) => {
    const {
        title,
        addButtonElement,
        showAddButton,
        addButtonText,
        onAddButtonClick,
        showSearchField,
        searchFieldPlaceholder,
        onSearchFieldChange,
        help
    } = props;
    return (
        <TabHeaderDiv>
            <SectionDiv>
                <H2 sx={{mr: "10px", color: "text.primary"}}>
                    {title}
                    {
                        help && <HelpModal helpKey={help.key} namespace={help.namespace} emptyText={help.emptyText} sx={{
                            ml: "5px",
                            cursor: "pointer",
                            verticalAlign: "bottom"
                        }}/>
                    }
                </H2>

                <AddButton addButtonElement={addButtonElement} showAddButton={showAddButton} addButtonText={addButtonText} onAddButtonClick={onAddButtonClick}/>
            </SectionDiv>
            <SectionDiv>
                {showSearchField && (
                    <SearchInput placeholder={searchFieldPlaceholder} onSearch={onSearchFieldChange}
                                 onChange={v => onSearchFieldChange(v)}/>
                )}
            </SectionDiv>
        </TabHeaderDiv>
    );
};

interface AddButtonProps {
    readonly addButtonElement?: ReactNode;
    readonly showAddButton?: boolean;
    readonly addButtonText?: string;
    readonly onAddButtonClick?: () => void;
}

const AddButton = (props: AddButtonProps) => {
    const {addButtonElement, showAddButton, addButtonText, onAddButtonClick} = props;

    if (addButtonElement) {
        return <>{addButtonElement}</>; // <> required for TypeScript
    }
    if (showAddButton) {
        return (
            <Button color="primary" variant="contained"
                    onClick={onAddButtonClick}>{addButtonText}</Button>
        )
    }
    return (<></>);
}
