import {AddUser, EditUser} from "./userQueries";
import configuredAxios from "../configuredAxios";

export interface UserResponse {
    readonly id: string;
    readonly email: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly username: string;
    readonly roles: string[]
}

export interface ResetCredentialsResponse {
    readonly password: string;
}

export interface AddUserResponse {
    readonly username: string;
    readonly initialPassword: string;
}

export const callGetUser = (id: string) => configuredAxios.get<UserResponse>(`/users/${id}`)
export const callGetUsers = (groupName: string) => configuredAxios.get<UserResponse[]>("/users", {params: {groupName}})
export const callAddUser = (userInput: AddUser) => configuredAxios.post<AddUserResponse>("/users/", userInput);
export const callUpdateUser = (userId: string, input: EditUser) =>  configuredAxios.put<UserResponse>(`/users/${userId}`, input);
export const callDeleteUser = (userId: string) => configuredAxios.delete(`/users/${userId}`);
export const callResetCredentials = (userId: string) => configuredAxios.post<ResetCredentialsResponse>(`/users/${userId}/resetCredentials`);
