import {IconProps} from "./index";
import {ClickableIcon} from "./ClickableIcon";
import MuiCloseIcon from "@mui/icons-material/Close";

export const CloseIcon = (props: IconProps) => {
    return (
        <ClickableIcon {...props}>
            <MuiCloseIcon/>
        </ClickableIcon>
    );
}
