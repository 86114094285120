import {AddVariableInput, UpdateVariableInput, VariableOverrideType, VariableType} from "./variableQueries";
import configuredAxios from "../configuredAxios";

export type VariableResponse = {
    readonly id: string;
    readonly name: string;
    readonly type: VariableType;
    readonly overrideType: VariableOverrideType;
    readonly value?: string;
    readonly createdAt: string;
    readonly createdBy: string;
    readonly updatedAt?: string;
    readonly updatedBy?: string;
};

export const callGetVariables = (projectId: string) => configuredAxios.get<VariableResponse[]>(uri(projectId));
export const callGetVariable = (projectId: string, variableId: string) => configuredAxios.get<VariableResponse>(uri(projectId, variableId));
export const callAddVariable = (projectId: string, variable: AddVariableInput) => configuredAxios.post<VariableResponse>(uri(projectId), variable)
export const callUpdateVariable = (projectId: string, variableId: string, variable: UpdateVariableInput) => configuredAxios.put<VariableResponse>(uri(projectId, variableId), variable)
export const callDeleteVariable = (projectId: string, variableId: string) =>  configuredAxios.delete(uri(projectId, variableId));

const uri = (projectId: string, variableId?: string) => `/projects/${projectId}/variables/${variableId || ""}`