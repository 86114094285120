import {useState} from "react";
import {OverviewSidebar} from "../common/overviewSidebar/OverviewSidebar";

import {TabHeader} from "../common/mui/tabHeader/TabHeader";
import {useRunnerModuleTranslation} from "../../util/i18n/useModuleTranslation";
import {RunnerList} from "./runnerList/RunnerList";
import {SecretView} from "./secretView/SecretView";
import {Box} from "@mui/material";
import {MainContentArea} from "../common/mui/mainLayout/MainContentArea";

export const RunnerOverview = () => {
    const {t} = useRunnerModuleTranslation();

    const [searchText, setSearchText] = useState<string>();
    return (
        <MainContentArea sidebar={<OverviewSidebar/>}>
            <TabHeader
                title={t("heading")}
                showSearchField={true}
                onSearchFieldChange={setSearchText}
                searchFieldPlaceholder={t("search")}
                help={{
                    namespace: "runner/general",
                    key: "general"
                }}
            />
            <Box sx={{mb: "20px"}}>
                <RunnerList searchText={searchText}/>
            </Box>
            <Box>
                <SecretView/>
            </Box>
        </MainContentArea>
    );
};
