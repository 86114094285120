import {HttpMethod} from "./commonTypes";
import {AxiosResponse} from "axios";

export const responseToExceptionResponse = (response: AxiosResponse<ExceptionResponse>) => response.data

export enum ExceptionType {
    INTERNAL = "INTERNAL",
    NOT_FOUND = "NOT_FOUND",
    FORBIDDEN = "FORBIDDEN",
    INPUT_VALIDATION = "INPUT_VALIDATION",
    CALCULATION = "CALCULATION",
    REMOTE = "REMOTE",
    FILE = "FILE",
    UNKNOWN = "UNKNOWN",
    OFFLINE = "OFFLINE",
    NOT_ALLOWED = "NOT_ALLOWED"
}

export type ExceptionResponse = {
    readonly message: string;
    readonly translationCode: string;
    readonly httpStatusCode: number;
    readonly type: ExceptionType
}


export const isExceptionResponse = (object: any): boolean => !!object["message"] && !!object["translationCode"];
export const toExceptionResponse = (object: any, code: number): ExceptionResponse => ({
    message: object["message"],
    translationCode: object["translationCode"],
    httpStatusCode: code,
    type: ExceptionType.UNKNOWN
});

export const toOfflineException = (): ExceptionResponse => ({
    message: "no connection to server available",
    translationCode: "offline",
    type: ExceptionType.OFFLINE,
    httpStatusCode: -1
})

export const toNotAllowedException = (): ExceptionResponse => ({
    message: "You are not logged in",
    translationCode: "notAllowed",
    type: ExceptionType.NOT_ALLOWED,
    httpStatusCode: 401
})

export const toInternalException = (object: any, status: number): ExceptionResponse => ({
    ...toExceptionResponse(object, status),
    type: ExceptionType.INTERNAL,
});

export type NotFoundExceptionResponse = ExceptionResponse & {
    class: string;
    id: string;
}

export const isNotFoundException = (object: any): boolean => object["translationCode"] === "notFoundError"
export const toNotFoundException = (object: any, code: number): NotFoundExceptionResponse => ({
    ...toExceptionResponse(object, code),
    class: object["class"],
    id: object["id"],
    type: ExceptionType.NOT_FOUND,
});

export type ForbiddenExceptionResponse = ExceptionResponse;

export const isForbiddenException = (object: any): boolean => object["translationCode"] === "forbidden";
export const toForbiddenException = (object: any, code: number): ForbiddenExceptionResponse => ({
    ...toExceptionResponse(object, code),
    type: ExceptionType.FORBIDDEN
});

export type InputValidationExceptionResponse = ExceptionResponse & {
    readonly class: string;
    readonly field: string;
    readonly reason: InputValidationExceptionReason
}

export enum InputValidationExceptionReason {
    MISSING = "MISSING",
    WRONG_FORMAT = "WRONG_FORMAT",
    STILL_EXISTS = "STILL_EXISTS",
    SHOULD_BE_NULL = "SHOULD_BE_NULL",
    DOES_NOT_EXIST = "DOES_NOT_EXIST"
}

export const isInputValidationException = (object: any): boolean => object["translationCode"] === "inputValidationError";
export const toInputValidationException = (object: any, code: number): InputValidationExceptionResponse => ({
    ...toExceptionResponse(object, code),
    type: ExceptionType.INPUT_VALIDATION,
    class: object["class"],
    field: object["field"],
    reason: object["reason"],
});

export type FileStorageExceptionResponse = ExceptionResponse & {
    readonly reason: string;
}
export const isFileStorageException = (object: any): boolean => object["translationCode"]?.toString().startsWith("fileStorage") || false;
export const toFileStorageException = (object: any, code: number): FileStorageExceptionResponse => ({
    ...toExceptionResponse(object, code),
    type: ExceptionType.FILE,
    reason: object["reason"],
});

export type RemoteRequestExceptionResponse = ExceptionResponse & {
    url: string;
    method: HttpMethod,
    reason: RemoteRequestExceptionReason
}

export enum RemoteRequestExceptionReason {
    NOT_FOUND = "NOT_FOUND",
    WRONG_URL_FORMAT = "WRONG_URL_FORMAT",
    UNKNOWN = "UNKNOWN",
    BAD_REQUEST = "BAD_REQUEST",
    FORBIDDEN = "FORBIDDEN",
    UNAUTHORIZED = "UNAUTHORIZED",
    METHOD_NOT_ALLOWED = "METHOD_NOT_ALLOWED",
    INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
    TIMEOUT = "TIMEOUT"
}

export const isRemoteRequestException = (object: any): boolean => object["translationCode"] === "remoteRequestException";
export const toRemoteRequestException = (object: any, code: number): RemoteRequestExceptionResponse => ({
    ...toExceptionResponse(object, code),
    type: ExceptionType.REMOTE,
    url: object["url"],
    method: object["method"],
    reason: object["reason"],
});

export type CalculationExceptionResponse = ExceptionResponse & {
    readonly reason: CalculationExceptionReason;
}

export enum CalculationExceptionReason {
    ROOT_FILE_NOT_FOUND = "ROOT_FILE_NOT_FOUND",
    NO_RUNNER_AVAILABLE = "NO_RUNNER_AVAILABLE"
}

export const isCalculationException = (object: any): boolean => object["translationCode"] === "calculationException";
export const toCalculationException = (object: any, code: number): CalculationExceptionResponse => ({
    ...toExceptionResponse(object, code),
    type: ExceptionType.CALCULATION,
    reason: object["reason"],
});
