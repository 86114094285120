import {useOverviewModuleTranslation} from "../../../../util/i18n/useModuleTranslation";
import {useState} from "react";
import {OVERVIEW_SIDEBAR_COLLAPSED_DEFAULT} from "../../../../state/constants";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {Chip, Paper, styled, Typography, Box} from "@mui/material";

export interface SidebarElement {
    readonly text: string;
    readonly icon: JSX.Element;
    readonly tagText?: string;
    readonly onClick: () => void;
}

interface SidebarProps {
    readonly elements: SidebarElement[];
}

const SidebarDiv = styled(Paper)({
    height: "100%",
    borderRight: "2px #5555 solid",
    borderRadius: "0"
});

const SidebarItemDiv = styled("div")(({theme}) => ({
    display: "flex",
    alignItems: "center",
    borderBottom: "1px #d9d9d9 solid",
    verticalAlign: "center",
    textAlign: "center",
    paddingRight: "10px !important",
    paddingLeft: "10px !important",
    height: "50px",
    cursor: "pointer",
    fontSize: theme.typography.fontSize * 1.2,
    fontWeight: theme.typography.fontWeightMedium,
}));

export const Sidebar = (props: SidebarProps) => {
    const {t} = useOverviewModuleTranslation("sidebar");

    const [collapsed, setCollapsed] = useState<boolean>(OVERVIEW_SIDEBAR_COLLAPSED_DEFAULT);

    const toggleCollapsed = () => setCollapsed(!collapsed);
    return (
        <SidebarDiv>
            <SidebarItem
                onClick={toggleCollapsed}
                icon={
                    collapsed
                        ? <ChevronRightIcon fontSize="large"/>
                        : <ChevronLeftIcon fontSize="large"/>
                }
                text={t("collapse")}
                collapsed={collapsed}
            />
            {
                props.elements.map((e, i) => <SidebarItem
                    onClick={e.onClick}
                    icon={e.icon}
                    text={e.text}
                    tagText={e.tagText}
                    collapsed={collapsed}
                    key={i}
                />)
            }
        </SidebarDiv>
    );
};

interface SidebarItemProps {
    readonly icon: any;
    readonly text: string;
    readonly tagText?: string;
    readonly collapsed: boolean;
    readonly onClick: () => void;
}

const SidebarItem = (props: SidebarItemProps) => {
    const {icon, text, tagText, collapsed, onClick} = props;

    return (
        <SidebarItemDiv onClick={onClick}>
            <Typography component="span" sx={{
                fontSize: "x-large",
                minWidth: "50px"
            }}>{icon}</Typography>
            {
                !collapsed && (
                    <Box display={"flex"} justifyContent={"space-between"} sx={{width: "100%"}}>
                        <Typography component="span" sx={{
                            padding: "0 20px ",
                            "&:active": {
                                backgroundColor: "rgba(212, 212, 212, 0.31)"
                            }
                        }}>{text}</Typography>
                        {
                            tagText && <Chip sx={{ml: 1}} size="small" label={tagText}/>
                        }
                    </Box>
                )
            }
        </SidebarItemDiv>
    );
};
