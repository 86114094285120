import BlockIcon from "@mui/icons-material/Block";
import {SimpleInfoPaper} from "./SimpleInfoPaper";

export interface EmptyListPaperProps {
    readonly label?: string;
}

export const EmptyListPaper = (props: EmptyListPaperProps) => {
    const {label} = props;
    return (
        <SimpleInfoPaper
            icon={<BlockIcon fontSize="large"/>}
            label={label}
        />
    );
};
