import {ReactElement, ReactNode} from "react";
import {Tooltip} from "@mui/material";

export interface NullableToolTipProps {
    readonly tooltip?: ReactNode;
    readonly children: ReactElement;
}

export const NullableToolTip = (props: NullableToolTipProps) => {
    const {tooltip, children} = props;

    if(tooltip) {
        return (
            <Tooltip title={tooltip}>
                {children}
            </Tooltip>
        )

    }
    return children;
}
