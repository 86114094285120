import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import InputIcon from "@mui/icons-material/Input";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import ShareIcon from "@mui/icons-material/Share";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ReorderIcon from "@mui/icons-material/Reorder";

export const DashboardIcon = DashboardOutlinedIcon;
export const SchedulingIcon = QueryBuilderIcon;
export const SimpleFormIcon = ReorderIcon;
export const VariableIcon = TuneOutlinedIcon;
export const DataSourceIcon = InputIcon;
export const FileIcon = InsertDriveFileOutlinedIcon;
export const CalculationIcon = PlayCircleOutlineIcon;
export const OutputChannelIcon = ShareIcon;
export const SettingsIcon = SettingsOutlinedIcon;
