import {useMutation, useQuery, useQueryClient} from "react-query";
import {
    callDeleteDateSource,
    callGetDataSources,
    callPostDataSource,
    callPutDataSource,
    callTestDataSource,
    DataSourceTestResponse
} from "./dataSourceRestCalls";
import {mapDataSourceResponseToDataSource} from "./dataSourceMapper";
import {CSVSettings, HttpAuth, HttpMethod} from "../commonTypes";
import {ProjectComponentType, useUpdateProjectComponents} from "../project/projectQueries";
import {ExceptionResponse} from "../exceptions";

const getDataSourceListQueryKey = (projectId: string) => `project-${projectId}-dataSources`;

export const useGetDataSources = (projectId: string) => {
    const queryKey = getDataSourceListQueryKey(projectId);
    return useQuery<DataSource[], ExceptionResponse>(queryKey, (): Promise<DataSource[]> => {
        return callGetDataSources(projectId)
            .then((data) => Promise.resolve(data.map(v => mapDataSourceResponseToDataSource(v)))
            );
    });
};

export const useAddDataSourceMutation = (projectId: string) => {
    const queryClient = useQueryClient();
    const {increment} = useUpdateProjectComponents(projectId);
    const addDataSource = (dataSourceInput: CreateDataSource) => {
        return callPostDataSource(projectId, dataSourceInput)
            .then((data) => Promise.resolve(mapDataSourceResponseToDataSource(data)));
    };

    return useMutation<DataSource, ExceptionResponse, CreateDataSource>(addDataSource, {
        onSuccess: () => {
            queryClient.invalidateQueries(getDataSourceListQueryKey(projectId));
            increment(ProjectComponentType.DATA_SOURCE)
        },
    });
};

export const useUpdateDataSourceMutation = (projectId: string) => {
    const queryClient = useQueryClient();
    const updateDataSource = (dataSource: DataSource) => {
        return callPutDataSource(projectId, dataSource)
            .then((data) => Promise.resolve(mapDataSourceResponseToDataSource(data)));
    };

    return useMutation<DataSource, ExceptionResponse, DataSource>(updateDataSource, {
        onSuccess: () => {
            queryClient.invalidateQueries(getDataSourceListQueryKey(projectId));
        },
    });
};

export const useDeleteDataSourceMutation = (projectId: string) => {
    const queryClient = useQueryClient();
    const {decrement} = useUpdateProjectComponents(projectId);
    const deleteDataSource = (dataSourceId: string) => {
        return callDeleteDateSource(projectId, dataSourceId)
            .then(() => Promise.resolve());
    };
    return useMutation<void, ExceptionResponse, string>(deleteDataSource, {
        onSuccess: () => {
            queryClient.invalidateQueries(getDataSourceListQueryKey(projectId));
            decrement(ProjectComponentType.DATA_SOURCE)
        },
    });
};

export interface DataSourceTestParams {
    readonly name: string,
    readonly variableName: string,
    readonly url: string;
    readonly urlType: DataSourceUrlType;
    readonly method: string;
    readonly fileType?: DataSourceFileType;
    readonly unzip?: Boolean;
    readonly csvSettings?: CSVSettings;
    readonly auth: HttpAuth;
    readonly useSavedPassword: boolean;
    readonly dataSourceId?: string;
}

export interface DataSourceZipTestResponseEntry {
    readonly name: string;
    readonly isDirectory: boolean;
    readonly sizeInBytes: number;
}

export const useTestDataSourceMutation = (projectId: string) => {
    const testDataSource = (params: DataSourceTestParams) => callTestDataSource(projectId, params);
    return useMutation<DataSourceTestResponse, ExceptionResponse, DataSourceTestParams>(testDataSource);
};

export enum DataSourceFileType {
    ANY = "ANY",
    CSV = "CSV",
    JSON = "JSON",
    XML = "XML",
    ZIP = "ZIP",
}

export const dataSourceFileTypes: DataSourceFileType[] = [
    DataSourceFileType.ANY,
    DataSourceFileType.CSV,
    DataSourceFileType.JSON,
    DataSourceFileType.XML,
    DataSourceFileType.ZIP
];

export enum DataSourceUrlType {
    STATIC = "STATIC",
    SCRIPT_COMPUTED = "SCRIPT_COMPUTED",
}

export const dataSourceUrlTypes: DataSourceUrlType[] = [DataSourceUrlType.STATIC, DataSourceUrlType.SCRIPT_COMPUTED];

export interface DataSourceHttpConnection {
    url: string,
    urlType: DataSourceUrlType
    method: HttpMethod,
}

export interface CreateDataSource {
    readonly name: string;
    readonly variableName: string;
    readonly connection: DataSourceHttpConnection;
    readonly auth: HttpAuth;
    readonly fileType: DataSourceFileType;
    readonly unzip?: boolean;
    readonly csvSettings?: CSVSettings;
}

export interface DataSource extends CreateDataSource {
    readonly id: string;
    readonly createdAt: string;
    readonly createdBy: string;
    readonly updatedAt: string;
    readonly updatedBy: string;
}
