import {useOverviewModuleTranslation} from "../../../../util/i18n/useModuleTranslation";
import {Chip, Tooltip} from "@mui/material";

export const OrganisationVisibilityTag = () => {
    const {t} = useOverviewModuleTranslation("projects.project");

    return (
        <Tooltip title={t("visibility.organisationTooltip")}>
            <Chip label={t("visibility.organisation")} variant="outlined" sx={{
                color: "#389e0d",
                background: "#f6ffed",
                borderColor: "#b7eb8f"
            }}/>
        </Tooltip>
    );
};

export const PrivateVisibilityTag = () => {
    const {t} = useOverviewModuleTranslation("projects.project");

    return (
        <Tooltip title={t("visibility.privateTooltip")}>
            <Chip label={t("visibility.private")} variant="outlined" sx={{
                color: "#cf1322",
                background: "#fff1f0",
                borderColor: "#ffa39e",
            }}/>
        </Tooltip>
    );
};
