import {MouseEventHandler, ReactNode} from "react";
import {Button, Theme} from "@mui/material";
import {SxProps} from "@mui/system";

export interface SubmitButtonProps {
    readonly text: ReactNode;
    readonly onClick?: MouseEventHandler<HTMLButtonElement>;
    readonly disabled?: boolean;
    readonly type?: "submit" | "reset" | "button";
    readonly sx?: SxProps<Theme>;
}

export const SubmitButton = (props: SubmitButtonProps) => {
    const {disabled, onClick, text, type, sx} = props;

    return (
        <Button type={type} onClick={onClick} color="primary" variant="contained" disabled={disabled} sx={sx}>{text}</Button>
    );
};
