import {CSSProperties, ReactNode} from "react";
import {DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {StyledDialog} from "./StyledDialog";
import {CancelButtonProps} from "../inputs/button/CancelButton";
import {SubmitButtonProps} from "../inputs/button/SubmitButton";
import {DialogFooter} from "./DialogFooter";

export interface FooterActionProps {
    cancelButton?: CancelButtonProps;
    submitButton?: SubmitButtonProps;
}

export interface SimpleDialogProps {
    readonly open: boolean;
    readonly onClose: (e: any) => void;
    readonly title: string;
    readonly ariaLabelledBy?: string;
    readonly children: ReactNode;
    // if footer has value, it will be use the Footer component. otherway it will create footer from footerActionProps
    readonly footer?: ReactNode;
    readonly footerActionProps?: FooterActionProps;
    readonly htmlMinWidth?: CSSProperties["minWidth"];
    readonly renderDialogContentTag?: boolean
}

export const SimpleDialog = (props: SimpleDialogProps) => {
    const {open, onClose, title, children, footer, ariaLabelledBy, htmlMinWidth, footerActionProps,renderDialogContentTag} = props;
    return (
        <StyledDialog
            open={open}
            onClose={onClose}
            aria-labelledby={ariaLabelledBy}
            htmlminwidth={htmlMinWidth}
        >
            <DialogTitle>{title}</DialogTitle>
            {
                renderDialogContentTag === false
                    ? children
                    :(
                        <DialogContent>
                            {children}
                        </DialogContent>
                    )
            }
            {
                footer
                    ? (
                        <DialogActions>
                            {footer}
                        </DialogActions>

                    )
                    : footerActionProps && (
                        <DialogFooter cancelButtonProps={footerActionProps.cancelButton} submitButtonProps={footerActionProps.submitButton}/>
                    )
            }
        </StyledDialog>
    );
};
