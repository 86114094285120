import {
    defaultNamespace,
    HelpTextContext,
    HelpTextContextContent,
    LngBasedNamespaces, LoadingNamespace,
    Namespace
} from "../middleware/helpTextContext";
import {callGetNamespace, defaultBasePath} from "../rest/restCalls";
import {ReactNode, useState} from "react";
import _ from "lodash";

export interface HelpTextContextProviderProps {
    readonly basePath?: string;
    readonly defaultLng?: string;
    readonly children: ReactNode;
}

const defaultHelpTextContextProviderProps: HelpTextContextProviderProps = {
    basePath: defaultBasePath,
    defaultLng: "en",
    children: ""
}

export const HelpTextContextProvider = (props: HelpTextContextProviderProps) => {
    const mergedProps = _.defaultsDeep(_.omitBy(props, _.isNil), defaultHelpTextContextProviderProps);
    const {children, basePath, defaultLng} = mergedProps;

    const [lng, setLng] = useState<string>(defaultLng);
    const [loadedNamespaces, setLoadedNamespaces] = useState<LngBasedNamespaces>({});
    const [loadingNamespaces, setLoadingNamespaces] = useState<LoadingNamespace[]>([]);
    const [failedNamespaces, setFailedNamespaces] = useState<LoadingNamespace[]>([]);

    const isNamespaceFailed = (namespace: string): boolean => {
        return failedNamespaces.some(it => it.lng === lng && it.namespace === namespace);
    }
    const isNamespaceLoaded = (namespace: string): boolean => {
        const lngNamespaces = loadedNamespaces[lng]
        return !!lngNamespaces && !!lngNamespaces[namespace];
    }
    const isNamespaceLoading = (namespace: string): boolean => {
        return loadingNamespaces.some(it => it.lng === lng && it.namespace === namespace);
    }

    const isNamespaceExists = (namespace: string = defaultNamespace): Promise<boolean> => {
        if (isNamespaceLoaded(namespace)) {
            return Promise.resolve(true);
        }
        return loadNamespace(namespace);
    }

    const loadNamespace = (namespace: string = defaultNamespace) => {
        if (isNamespaceLoaded(namespace) || isNamespaceLoading(namespace)) {
            return Promise.resolve(true);
        }
        if (isNamespaceFailed(namespace)) {
            return Promise.resolve(false);
        }
        setLoadingNamespaces((state) => {
            return [...state, {lng: lng, namespace: namespace}];
        });
        const deleteFromLoading = () => {
            setLoadingNamespaces((state) => state.filter(it => it.lng !== lng && it.namespace !== namespace));
        };

        const addToFailedNamespaces = () => setFailedNamespaces((state) => [...state, {lng, namespace,}]);


        return callGetNamespace(basePath, lng, namespace)
            .then(workspace => {
                setLoadedNamespaces((state) => ({
                    ...state,
                    [lng]: {
                        ...state[lng],
                        [namespace]: workspace
                    }
                }));
                deleteFromLoading();
                return Promise.resolve(true);
            })
            .catch(e => {
                console.error(`could not load help workspace of language "${lng}" and namespace "${namespace}"`, e)
                deleteFromLoading();
                addToFailedNamespaces();
                return Promise.resolve(false);
            });
    }

    const getNamespaceContent = (namespace: string = defaultNamespace): Promise<Namespace | undefined> => {
        if (isNamespaceLoaded(namespace)) {
            const lngWS = loadedNamespaces[lng]
            return Promise.resolve(lngWS && lngWS[namespace]);
        }
        return loadNamespace(namespace).then(_ => {
            const lngWS = loadedNamespaces[lng]
            return Promise.resolve(lngWS && lngWS[namespace]);
        })
    }

    const value: HelpTextContextContent = {
        getLanguage: () => lng,
        setLanguage: setLng,
        getNamespaceContent,
        loadNamespace,
        isNamespaceExists,
        isNamespaceLoaded,
        isNamespaceLoading

    }
    return (
        <HelpTextContext.Provider value={value}>
            {children}
        </HelpTextContext.Provider>
    );
};
