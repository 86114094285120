import {useHistory} from "react-router-dom";
import StorageIcon from "@mui/icons-material/Storage";
import GroupIcon from "@mui/icons-material/Group";
import DashboardIcon from "@mui/icons-material/Dashboard";
import {useOverviewModuleTranslation} from "../../../util/i18n/useModuleTranslation";
import {isAdmin} from "../../../../security/keycloakConfig";
import {Sidebar, SidebarElement} from "../mui/sidebar/Sidebar";

export const OverviewSidebar = () => {
    const {t} = useOverviewModuleTranslation("sidebar");
    const history = useHistory();

    const isUserAdmin = isAdmin();

    const goToProjects = () => {
        history.push("/projects");
    };

    const goToOrganisation = () => {
        history.push("/organisation");
    };
    const goToRunners = () => {
        history.push("/runners");
    };

    const baseElements: SidebarElement[] = [
        {
            icon: <DashboardIcon style={{fontSize: "x-large"}}/>,
            text: t("projects"),
            onClick: goToProjects,
        },
        {
            icon: <GroupIcon style={{fontSize: "x-large"}}/>,
            text: t("organisation"),
            onClick: goToOrganisation,
        }
    ];

    const adminElements: SidebarElement[] = [
        {
            icon: <StorageIcon style={{fontSize: "x-large"}}/>,
            text: t("runners"),
            onClick: goToRunners,
        },
    ];

    const elements = isUserAdmin
        ? [...baseElements, ...adminElements]
        : baseElements;

    return (
        <Sidebar
            elements={elements}
        />
    );
};
