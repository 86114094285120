import {IconButton, InputBase, Paper} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {ChangeEvent, useState} from "react";

export interface SearchInputProps {
    readonly placeholder: string;
    readonly onSearch: (value: string) => void;
    readonly onChange: (value: string) => void;
}

export const SearchInput = (props: SearchInputProps) => {
    const {placeholder, onChange, onSearch} = props;
    const [searchValue, setSearchValue] = useState<string>("");

    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        onChange(e.target.value);
    };

    return (
        <Paper
            component="form"
            sx={{p: "2px 4px", display: "flex", alignItems: "center", width: 400}}
        >
            <InputBase
                sx={{ml: 1, flex: 1}}
                placeholder={placeholder}
                onChange={onInputChange}
                value={searchValue}
                inputProps={{"aria-label": "search"}}
            />
            <IconButton onClick={() => onSearch(searchValue)} sx={{p: "10px"}} aria-label="search">
                <SearchIcon/>
            </IconButton>
        </Paper>
    );
};
