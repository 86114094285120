import {Page} from "../components/common/mui/page/Page";
import {Navbar} from "../components/common/mui/navbar/Navbar";
import {useSimpleFormModuleTranslation} from "../util/i18n/useModuleTranslation";
import {Container} from "@mui/material";
import {useParams} from "react-router-dom";
import {MainContentArea} from "../components/common/mui/mainLayout/MainContentArea";
import {useGetSimpleFormTemplate} from "../rest/simpleForm/simpleFormQueries";
import {LoadingPaper} from "../components/common/mui/paper/LoadingPaper";
import {ErrorPaper} from "../components/common/mui/paper/ErrorPaper";
import {SimpleFormInput} from "../components/simpleForm/SimpleFormInput";

export const SimpleFormInputPage = () => {
    const params = useParams<{ code: string }>();
    const {t} = useSimpleFormModuleTranslation("inputForm");
    const {data: form, isError, error, isLoading, isSuccess} = useGetSimpleFormTemplate(params.code)
    return (
        <Page>
            <Navbar title={t("title")}/>
            <MainContentArea>
                <Container>
                    {isLoading && <LoadingPaper loadingText={t("loading")}/>}
                    {isError && <ErrorPaper label={t(`error.${error.translationCode || "UNKNOWN"}`)}/>}
                    {isSuccess && form && <SimpleFormInput form={form} code={params.code}/>}
                </Container>
            </MainContentArea>
        </Page>
    );
};
