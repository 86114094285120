import {Box, Typography} from "@mui/material";
import React, {ReactNode} from "react";
import {SimplePaper} from "./SimplePaper";

export interface SimpleInfoPaperProps {
    readonly icon?: ReactNode;
    readonly label?: ReactNode;
}

export const SimpleInfoPaper = (props: SimpleInfoPaperProps) => {
    return (
        <SimplePaper>
            <SimpleInfo {...props}/>
        </SimplePaper>
    )
}

export const SimpleInfo = (props: SimpleInfoPaperProps) => {
    const {icon, label} = props;
    return (
        <Box  sx={{display: "flex"}}>
            {icon && icon}
            <Typography component="span" sx={{
                marginLeft: icon && "10px",
                display: "flex",
                alignItems: "center",
                // alignContent: "center"
            }}>
                {label && label}
            </Typography>
        </Box>
    )
}
