import {Box, Chip, Tooltip, Typography} from "@mui/material";
import {Runner, useGetRunners} from "../../../rest/runnerQueries";
import MemoryIcon from "@mui/icons-material/Memory";
import FilterNoneIcon from "@mui/icons-material/FilterNone";
import {fileSizeToFormattedSize} from "../../../util/util";
import {useRunnerModuleTranslation} from "../../../util/i18n/useModuleTranslation";
import {RIcon} from "../../common/mui/icons";
import {LoadingPaper} from "../../common/mui/paper/LoadingPaper";
import {ErrorPaper} from "../../common/mui/paper/ErrorPaper";
import {EmptyListPaper} from "../../common/mui/paper/EmptyListPaper";
import {PaperList} from "../../common/mui/list/PaperList";
import {SimplePaper} from "../../common/mui/paper/SimplePaper";
import _ from "lodash";

export interface RunnerListProps {
    readonly searchText: string;
}

export const RunnerList = (props: RunnerListProps) => {
    const {searchText} = props;

    const {t} = useRunnerModuleTranslation("runnerList");

    const {data = [], isLoading, isError} = useGetRunners();

    const filteredData = searchText?.trim().length > 0
        ? data.filter(r => filterRunner(r, props.searchText)) ?? []
        : data;

    if (isLoading) {
        return <LoadingPaper loadingText={t("loading")}/>
    }

    if (isError) {
        return <ErrorPaper label={t("error")}/>;
    }

    if (data.length === 0) {
        return <EmptyListPaper label={t("empty")}/>
    }

    if (filteredData.length === 0) {
        return <EmptyListPaper label={t("emptyWithSearchText", {searchText})}/>
    }

    return (
        <PaperList papers={
            sort(filteredData).map((runner) => <RunnerCard runner={runner} key={runner.id}/>)
        }/>
    );
};

const RunnerCard = ({runner}: { runner: Runner }) => {
    const {name, description, tags, amountOfMemory, numberOfCores, rversion, version} = runner;
    const {t} = useRunnerModuleTranslation("runnerList.item");
    return (
        <SimplePaper
            title={`${name} (${t("version", {version})})`}
            subTitle={tags.map((tag, i) => <Chip size="small" sx={{mr: "5px"}} key={i} label={tag}
                                                 variant="outlined"
                                                 color="primary"/>)}
        >
                <Typography>
                    {description}
                </Typography>
            <Box sx={{mt: 1}}>
                    <Tooltip title={t("amountOfMemory")}>
                        <MemoryIcon fontSize="small" sx={{ml: 1}}/>
                    </Tooltip>
                    <Typography component="span" sx={{ml: 1}}>
                        {fileSizeToFormattedSize(amountOfMemory, "MB")}
                    </Typography>
                    <Tooltip title={t("numberOfCores")}>
                        <FilterNoneIcon fontSize="small" sx={{ml: 1}}/>
                    </Tooltip>
                    <Typography  component="span" sx={{ml: 1}}>
                        {numberOfCores}
                    </Typography>
                    <RIcon sx={{ml: 1}}/>
                    <Typography component="span" sx={{ml: 1}}>
                        {rversion}
                    </Typography>
            </Box>
        </SimplePaper>
    );
};

const filterRunner = (runner: Runner, searchTerm: string): boolean => {
    const {name, description, tags} = runner;

    return name.includes(searchTerm)
        || description?.includes(searchTerm)
        || tags.find(t => t.includes(searchTerm)) !== undefined;
};
const sort = (projects:Runner[]): Runner[] => {
    return _.orderBy(projects, [p => p.name.toLowerCase()], ["asc"]);
}