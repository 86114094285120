import {FormControl, FormControlProps} from "@mui/material";

export const StremoFormControl = (props: FormControlProps) => {
    return (
        <FormControl {...props} sx={{
            margin: "10px 0",
            width: "100%",
        }}>
            {props.children && props.children}
        </FormControl>
    )
}
