import {IconProps} from "./index";
import {ClickableIcon} from "./ClickableIcon";
import DeleteIcon from "@mui/icons-material/Delete";

export const DeleteIconButton = (props: IconProps) => {
    return (
        <ClickableIcon {...props}>
            <DeleteIcon/>
        </ClickableIcon>
    );
}
