import {ElementType, ReactNode} from "react";
import {Theme, Typography, SxProps} from "@mui/material";
import {OverridableStringUnion} from "@mui/types";
import {Variant} from "@mui/material/styles/createTypography";
import {TypographyPropsVariantOverrides} from "@mui/material/Typography/Typography";

interface HeadingProps {
    readonly children: ReactNode;
    readonly small?: ReactNode;
    readonly sx?: SxProps<Theme>
}

export const H1 = (props: HeadingProps) => <H component="h1" variant="h1" small={props.small} sx={props.sx}>{props.children}</H>
export const H2 = (props: HeadingProps) => <H component="h2" variant="h2" small={props.small} sx={props.sx}>{props.children}</H>
export const H3 = (props: HeadingProps) => <H component="h3" variant="h3" small={props.small} sx={props.sx}>{props.children}</H>
export const H4 = (props: HeadingProps) => <H component="h4" variant="h4" small={props.small} sx={props.sx}>{props.children}</H>
export const H5 = (props: HeadingProps) => <H component="h5" variant="h5" small={props.small} sx={props.sx}>{props.children}</H>
export const H6 = (props: HeadingProps) => <H component="h6" variant="h6" small={props.small} sx={props.sx}>{props.children}</H>


interface InternalHeadingProps extends HeadingProps {
    readonly component: ElementType;
    readonly variant: OverridableStringUnion<Variant | "inherit", TypographyPropsVariantOverrides>
}

const H = (props: InternalHeadingProps) => {
    const {small, children, component, sx, variant} = props;

    const mergedSx: SxProps<Theme> = {
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        pb: 1,
        ...sx
    };

    return (
        <Typography component={component} sx={mergedSx} variant={variant}>
            {children}
            {
                small && <Typography component="small">{small}</Typography>
            }
        </Typography>
    );
};