import {useState} from "react";
import * as yup from "yup";


export const mapRole = (userRole: UserRole): string => {
    switch (userRole) {
        case UserRole.ADMIN:
            return "Admin";
        case UserRole.DEVELOPER:
            return "Developer";
        case UserRole.VIEWER:
            return "Viewer";
    }
};

const schema = yup.object().shape({
    username: yup.string().trim().required("MISSING"),
    firstName: yup.string().trim().required("MISSING"),
    surname: yup.string().trim().required("MISSING"),
    email: yup.string().trim().email("WRONG_FORMAT"),
    role: yup.string().trim().required("MISSING"),
});

export enum UserRole {
    ADMIN = "ADMIN",
    DEVELOPER = "DEVELOPER",
    VIEWER = "VIEWER"
}

interface EditUserFormValues {
    readonly username?: string;
    readonly email?: string;
    readonly firstName?: string;
    readonly surname?: string;
    readonly role?: UserRole;
}

interface FormErrors {
    username?: string;
    email?: string;
    firstName?: string;
    surname?: string;
    role?: string;
}

interface FormDirty {
    username?: boolean;
    email?: boolean;
    firstName?: boolean;
    surname?: boolean;
    role?: boolean;
}

export interface EditUserFormData {
    readonly username?: string;
    readonly email?: string;
    readonly firstName?: string;
    readonly surname?: string;
    readonly role: UserRole;
    readonly on: {
        usernameChange: (value: string) => void;
        emailChange: (value: string) => void;
        firstNameChange: (value: string) => void;
        surnameChange: (value: string) => void;
        roleChange: (value: UserRole) => void;
    }
    readonly isValid: () => boolean;
    readonly errors: FormErrors;
    readonly dirty: FormDirty;
    readonly submit: () => EditUserFormValues;
}

export const useEditUserForm = (defaults?: EditUserFormValues): EditUserFormData => {
    const [username, setUsername] = useState<string>(defaults?.username);
    const [email, setEmail] = useState<string>(defaults?.email || "");
    const [firstName, setFirstName] = useState<string>(defaults?.firstName);
    const [surname, setSurname] = useState<string>(defaults?.surname);
    const [role, setRole] = useState<UserRole>(defaults?.role || UserRole.DEVELOPER);
    const [errors, setErrors] = useState<FormErrors>({});
    const [valid, setValid] = useState<boolean>(true);
    const [dirty, setDirty] = useState<FormDirty>({
        email: false,
        firstName: false,
        role: false,
        surname: false,
        username: false,
    })

    const getForm = (): EditUserFormValues => ({
        username,
        email,
        firstName,
        surname,
        role
    });

    const validate = (form: EditUserFormValues) => {
        schema.validate(form)
            .then(() => {
                setErrors({});
                setValid(true);
            })
            .catch((err) => {
                const newErrors = {};
                newErrors[err.path] = err.message
                setErrors(newErrors);
                setValid(false);
            });
    };

    return {
        username: username,
        email: email,
        firstName: firstName,
        surname: surname,
        role: role,
        errors,
        isValid: () => valid,
        on: {
            usernameChange: (value) => {
                setUsername(value);
                setDirty((d) => ({...d, username: true}));
                validate({...getForm(), username: value})
            },
            emailChange: (value) => {
                setEmail(value);
                setDirty((d) => ({...d, email: true}));
                validate({...getForm(), email: value})
            },
            firstNameChange: (value) => {
                setFirstName(value);
                setDirty((d) => ({...d, firstName: true}));
                validate({...getForm(), firstName: value})
            },
            surnameChange: (value) => {
                setSurname(value);
                setDirty((d) => ({...d, surname: true}));
                validate({...getForm(), surname: value})
            },
            roleChange: (value) => {
                setRole(value);
                setDirty((d) => ({...d, role: true}));
                validate({...getForm(), role: value})
            },
        },
        dirty,
        submit: () =>({
            username,
            email,
            firstName,
            surname,
            role,
        }),
    };
};