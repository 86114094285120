import {DialogActions} from "@mui/material";
import {CancelButton, CancelButtonProps} from "../inputs/button/CancelButton";
import {SubmitButton, SubmitButtonProps} from "../inputs/button/SubmitButton";

interface DialogFooterProps {
    readonly cancelButtonProps?: CancelButtonProps;
    readonly submitButtonProps?: SubmitButtonProps;
}

export const DialogFooter = (props: DialogFooterProps) => {
    const {cancelButtonProps, submitButtonProps} = props;
    return (
        <DialogActions>
            {
                cancelButtonProps && <CancelButton {...cancelButtonProps}/>
            }
            {
                submitButtonProps && <SubmitButton {...submitButtonProps}/>
            }
        </DialogActions>
    );
};
