import {useOverviewModuleTranslation} from "../../../../util/i18n/useModuleTranslation";
import {getFormattedDate, maxStringLength} from "../../../../util/util";
import {SimplePaper} from "../../../common/mui/paper/SimplePaper";
import {OrganisationVisibilityTag, PrivateVisibilityTag} from "./VisibilityTags";
import {LabelValuePair} from "../../../common/mui/section/LabelValuePair";
import {Project, ProjectComponents, ProjectVisibility} from "../../../../rest/project/projectQueries";
import {Alert, Box, Chip, Stack, Tooltip} from "@mui/material";
import {
    CalculationIcon,
    DataSourceIcon,
    FileIcon,
    OutputChannelIcon,
    SchedulingIcon,
    VariableIcon
} from "../../../projectDetails/icons/ProjectComponentIcons";
import {ReactElement} from "react";

interface ProjectCardProps {
    readonly project: Project;
    readonly onClick: () => void;
}

export const ProjectCard = (props: ProjectCardProps) => {
    const {project, onClick} = props;
    const {t} = useOverviewModuleTranslation("projects.project");

    return (
        <SimplePaper
            onClick={onClick}
            title={project.name}
            subTitle={project.visibility === ProjectVisibility.ORGANISATION ?
                <OrganisationVisibilityTag/> : <PrivateVisibilityTag/>}
        >
            <Box sx={{mb: 2}}>
                <ProjectComponentList components={project.components}/>
            </Box>
            {
                project.components.lastCalculationSuccessfully === false && (
                    <Box sx={{mb: 2}}>
                        <Alert severity={"error"}>{t("lastCalculationFailed")}</Alert>
                    </Box>
                )
            }
            <Box>
                {
                    project.description &&
                    <ProjectCardDetail label={t("description")}
                                       value={maxStringLength(project.description, 250)}/>
                }
                {
                    project.components.lastCalculationFinished &&
                    <ProjectCardDetail label={t("lastCalculationFinished")}
                                       value={getFormattedDate(project.components.lastCalculationFinished)}/>
                }
                {
                    project.createdAt &&
                    <ProjectCardDetail label={t("createdAt")} value={getFormattedDate(project.createdAt)}/>
                }
            </Box>
        </SimplePaper>
    );
};

const ProjectCardDetail = (props: { label: string, value: any }) => {
    return (
        <LabelValuePair label={props.label} value={props.value} sml={12} smv={12}/>
    );
};

const ProjectComponentList = (props: { components: ProjectComponents }) => {
    const {
        numberOfFiles,
        numberOfOutputChannels,
        numberOfCalculations,
        numberOfDataSources,
        numberOfVariables,
        numberOfScheduledJobs
    } = props.components;
    const {t} = useOverviewModuleTranslation("projects.project.components");

    return (
        <Stack direction="row" spacing={1}>
            {
                numberOfScheduledJobs > 0 &&
                <ComponentTag icon={<SchedulingIcon/>} counts={numberOfScheduledJobs} name={t("scheduling")}/>
            }
            {
                numberOfVariables > 0 &&
                <ComponentTag icon={<VariableIcon/>} counts={numberOfVariables} name={t("variables")}/>
            }
            {
                numberOfDataSources > 0 &&
                <ComponentTag icon={<DataSourceIcon/>} counts={numberOfDataSources} name={t("dataSources")}/>
            }
            {
                numberOfFiles > 0 &&
                <ComponentTag icon={<FileIcon/>} counts={numberOfFiles} name={t("files")}/>
            }
            {
                numberOfCalculations > 0 &&
                <ComponentTag icon={<CalculationIcon/>} counts={numberOfCalculations} name={t("calculations")}/>
            }
            {
                numberOfOutputChannels > 0 &&
                <ComponentTag icon={<OutputChannelIcon/>} counts={numberOfOutputChannels} name={t("outputChannels")}/>
            }
        </Stack>
    );
};

interface ComponentTagProps {
    readonly name: string,
    readonly counts: number;
    readonly icon: ReactElement;
}

const ComponentTag = (props: ComponentTagProps) => {
    const {name, counts, icon} = props;
    return (
        <Tooltip title={name}>
            <Chip size="small" icon={icon} label={counts}/>
        </Tooltip>
    );
}