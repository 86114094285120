import {useHelp} from "../../../../../helpTextFramework";
import DOMPurify from "dompurify";

import HelpIcon from "@mui/icons-material/Help";
import {DialogContentText} from "@mui/material";
import {ReactNode, useState} from "react";
import {HelpTextOutputObject} from "../../../../../helpTextFramework/middleware/useHelp";
import {Loading} from "../loading/Loading";
import {SxProps} from "@mui/system";
import {SimpleDialog} from "../dialog/SimpleDialog";

interface HelpModalProps {
    readonly namespace?: string;
    readonly helpKey: string
    readonly emptyText?: string;
    readonly sx?: SxProps;
}

export const HelpModal = (props: HelpModalProps) => {
    const {emptyText, helpKey, namespace, sx} = props;
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    useHelp(namespace); // preload namespace
    return (
        <>
            <HelpIcon sx={sx} onClick={() => setModalOpen(true)} fontSize="large"/>
            {
                modalOpen && (
                    <HelpModalContent
                        helpKey={helpKey}
                        namespace={namespace}
                        emptyText={emptyText}
                        handleClose={() => setModalOpen(false)}
                    />
                )
            }
        </>
    );
};

interface HelpModalContentProps {
    readonly namespace?: string;
    readonly helpKey: string
    readonly emptyText?: string;
    readonly handleClose: () => void;
}

const HelpModalContent = (props: HelpModalContentProps) => {
    const {emptyText, helpKey, namespace, handleClose} = props;
    const {h, loading} = useHelp(namespace);
    const help = h(helpKey, emptyText);

    if (loading) {
        return (
            <DialogFrame handleClose={handleClose}>
                <Loading/>
            </DialogFrame>
        )
    }

    if (typeof help === "string") {
        return (
            <DialogFrame handleClose={handleClose}>
                <DialogContentText>{help}</DialogContentText>
            </DialogFrame>
        );
    }
    return (
        <ComplexHelpModalContent handleClose={handleClose} help={help as HelpTextOutputObject}/>
    );
};

interface ComplexHelpModalContentProps {
    readonly help: HelpTextOutputObject
    readonly handleClose: () => void;
}

const ComplexHelpModalContent = (props: ComplexHelpModalContentProps) => {
    const {help, handleClose} = props;
    return (
        <DialogFrame handleClose={handleClose} title={help.title}>
            <div dangerouslySetInnerHTML={{"__html": DOMPurify.sanitize(help.content)}}/>
        </DialogFrame>
    );
};

interface DialogFrameProps {
    readonly handleClose: () => void;
    readonly title?: string;
    readonly children: ReactNode;
}

const DialogFrame = (props: DialogFrameProps) => {
    const {children, handleClose, title} = props;

    return (
        <SimpleDialog onClose={handleClose} open={true} title={title}>
            {children}
        </SimpleDialog>
    );
};
