import {Alert} from "@mui/material";
import {useOrganisationModuleTranslation} from "../../../../util/i18n/useModuleTranslation";
import {InputValidationExceptionResponse} from "../../../../rest/exceptions";

interface ErrorAlertProps {
    error: InputValidationExceptionResponse;
    translationBaseKey: string
}

export const ErrorAlert = (props:ErrorAlertProps) => {
    const {error, translationBaseKey} = props;

    const {t} = useOrganisationModuleTranslation(translationBaseKey);

    const {reason, field, translationCode} = error;
    if(reason && field) {
        return (
            <Alert severity="error">{t("feedback.error." + field + "." + reason)}</Alert>
        );
    }
    const code = translationCode || "unknown"
    return (
        <Alert severity="error">{t("feedback.error." + code)}</Alert>
    );
};
