import {ReactNode, useState} from "react";
import {useTranslation} from "react-i18next";
import HomeIcon from "@mui/icons-material/Home";

import {useHistory} from "react-router-dom";
import i18next from "i18next";
import {getAvailableOtherLanguages, getLanguageLabel} from "../../../../../i18n";
import {keycloak, userInfo} from "../../../../../security/keycloakConfig";
import {useGetAppInfo} from "../../../../rest/appInfoQueries";
import {AppBar, IconButton, Link, Menu, MenuItem, styled, Toolbar, Typography, useTheme} from "@mui/material";
import {useHelpTextContext} from "../../../../../helpTextFramework";
import {H1} from "../headings/Headings";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import {useColorModeContext} from "../../../../mui-styles/darkmodeProvider";
import {Bold} from "../typography/Typography";
import {R_DATACENTER_MAIN_COLOR} from "../../../../mui-styles/muiStyles";
import {Avatar} from "../avatar/Avatar";

const StyledAppBar = styled(AppBar)(({theme}) => ({
    flexGrow: 1,
    backgroundColor: theme.rDataCenter.backgroundColor,
}));

export interface NavbarDataProps {
    readonly title: string | ReactNode;
}

export const Navbar = (props: NavbarDataProps) => {
    const {title} = props;
    const history = useHistory();

    const onHomeButtonClick = () => {
        history.push("/projects/");
    };

    return (
        <StyledAppBar position="static">
            <Toolbar>
                <IconButton edge="start" sx={{mr: 2}} color="inherit" aria-label="home"
                            onClick={onHomeButtonClick}>
                    <HomeIcon fontSize="large"/>
                </IconButton>
                <H1 sx={{flexGrow: 1, color: R_DATACENTER_MAIN_COLOR}}>{title}</H1>
                <AvatarDropdown/>
            </Toolbar>
        </StyledAppBar>
    );
};

const StyledAvatar = styled(Avatar)({cursor: "pointer"});

const AvatarDropdown = () => {
    const {t} = useTranslation();
    const {data} = useGetAppInfo();

    const {setLanguage} = useHelpTextContext();
    const theme = useTheme();
    const colorMode = useColorModeContext();

    const logout = () => keycloak.logout();

    const onLanguageItemClick = (language: string) => {
        return () => {
            i18next.changeLanguage(language);
            setLanguage(language)
        };
    };

    const onAvatarClick = (e: any) => {
        e.preventDefault();
        setMenuOpen(true)
    };
    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    const handleClose = () => setMenuOpen(false);

    return (
        <div id="avatar">
            <StyledAvatar aria-controls="avatar-menu" aria-haspopup="true"
                          name={userInfo?.fullName} onClick={onAvatarClick}/>
            <Menu
                id="avatar-menu"
                anchorEl={document.getElementById("avatar")}
                keepMounted
                open={menuOpen}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose}><Bold component="span">{userInfo?.username}</Bold></MenuItem>
                <MenuItem onClick={handleClose}><Bold component="span">{userInfo?.email}</Bold></MenuItem>
                {
                    getAvailableOtherLanguages().map((language, index) => {
                        return (
                            <MenuItem key={index} onClick={onLanguageItemClick(language)}>
                                {getLanguageLabel(language)}
                            </MenuItem>
                        );
                    })
                }
                <MenuItem onClick={colorMode.toggleColorMode}>
                    {theme.palette.mode === "dark"
                        ? (
                            <>
                                <Brightness7Icon/>
                                <Typography component="span" sx={{ml: "5px"}}>{t("lightMode")}</Typography>
                            </>
                        )
                        : (
                            <>
                                <Brightness4Icon/>
                                <Typography component="span" sx={{ml: "5px"}}>{t("darkMode")}</Typography>
                            </>
                        )
                    }
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Link
                        href="/about">
                        {t("about")}
                    </Link></MenuItem>
                <MenuItem onClick={handleClose}> {t("version")} {data?.version ?? "-"}</MenuItem>
                <MenuItem onClick={logout}>{t("logout")}</MenuItem>
            </Menu>
        </div>
    );
};
