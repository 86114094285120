import {ProjectVisibility} from "../rest/project/projectQueries";

export const OVERVIEW_SIDEBAR_COLLAPSED_DEFAULT = false;

export const PROJECT_DETAILS_SIDEBAR_COLLAPSED_DEFAULT = false;

export const DEFAULT_PROJECT_VISIBILITY = ProjectVisibility.PRIVATE;

export const R_SCRIPT_EDITOR_AUTOMATIC_SAVE_ENABLED = true;

export const R_SCRIPT_EDITOR_AUTOMATIC_SAVE_INTERVAL = 10_000;

export const SMALL_DIALOG_WIDTH = window.innerWidth < 500 ? window.innerWidth : 500;
export const MIDDLE_DIALOG_WIDTH = window.innerWidth < 800 ? window.innerWidth : 800;
export const LARGE_DIALOG_WIDTH = window.innerWidth < 1100 ? window.innerWidth : 1100;