import i18n from "i18next";
import moment from "moment-timezone";
import _ from "lodash";

export const getLanguage = (): string => {
    return i18n.language ||
        (typeof window !== "undefined" && window.localStorage.i18nextLng) ||
        "en";
};

const getLanguageGroup = (): string => {
    const language = getLanguage();
    const languageGroup = language.includes("-")
        ? language.split("-")[0]
        : language;
    return languageGroup
}

export const getDateFormat = (): string => {


    switch (getLanguageGroup().toLowerCase()) {
        case "en":
            return "MM/DD/YYYY HH:mm z";
        case "de":
            return "DD.MM.YYYY HH:mm z";
    }
    return "MM/DD/YYYY HH:mm z";
};

export const getDateFormatWithSeconds = (): string => {
    switch (getLanguageGroup().toLowerCase()) {
        case "en":
            return "MM/DD/YYYY HH:mm:ss:SSSS z";
        case "de":
            return "DD.MM.YYYY HH:mm:ss:SSSS z";
    }
    return "MM/DD/YYYY HH:mm:ss:SSSS z";
};


export const getFormattedDate = (date: string): string => moment(date).utc().format(getDateFormat());

export const getFormattedDateWithSeconds = (date: string): string => moment(date).utc().format(getDateFormatWithSeconds());

export const maxStringLength = (value: string, maxLength: number) => {
    if (value.length > maxLength) {
        return value.substr(0, maxLength - 3) + "...";
    }
    return value;
};
export const getFormattedTimeDiff = (start: string, end: string): { value: number, unit: "SECONDS" | "MINUTES" | "HOURS" | "DAYS"  } => {
    const seconds = moment(end).diff(start, "seconds");
    if(seconds < 60) {
        return {
            value: seconds,
            unit: "SECONDS"
        };
    }
    const minutes = seconds / 60;
    if(minutes < 60) {
        return {
            value: _.round(minutes, 2),
            unit: "MINUTES"
        };
    }
    const hours = minutes / 60;
    if(hours < 24) {
        return {
            value: _.round(hours, 2),
            unit: "HOURS"
        };
    }
    const days = hours / 24;
    return {
        value: _.round(days, 2),
        unit: "DAYS",
    };
};


export const fileSizeToFormattedSize = (size: number, unit: "B" | "KB" | "MB" | "GB"): string => {
    switch (unit) {
        case "B":
            return bytesToFormattedSize(size);
        case "KB":
            return bytesToFormattedSize(size * 1024);
        case "MB":
            return bytesToFormattedSize(size * 1024 * 1024);
        case "GB":
            return bytesToFormattedSize(size * 1024 * 1024 * 1024);
    }
    return `${size}`;
};

export const bytesToFormattedSize = (size: number): string => {
    if (size < 1024) {
        return `${size} B`;
    }
    const kb = size / 1024;
    if (kb < 1024) {
        return `${_.round(kb, 2)} KB`;
    }
    const mb = kb / 1024;
    if (mb < 1024) {
        return `${_.round(mb, 2)} MB`;
    }
    const gb = mb / 1024;
    return `${_.round(gb, 2)} GB`;
};
