import {CSSProperties, ReactNode} from "react";
import {Box} from "@mui/material";

interface ListPaperFrameProps {
    readonly children: ReactNode;
    readonly minPaperWidth?: CSSProperties["minWidth"];
}

export const ListPaperFrame = (props: ListPaperFrameProps) => {
    const {children, minPaperWidth = "275px"} = props;
    return (
        <Box sx={{
            flexGrow:1,
            minWidth: minPaperWidth,
            maxWidth: "100%",
        }}>
            {children}
        </Box>
    );
};