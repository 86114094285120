import {ReactNode} from "react";
import {Grid, styled, Typography} from "@mui/material";

interface LabelValuePairProps {
    readonly label: string;
    readonly value: string | ReactNode;
    readonly sml?: number;
    readonly smv?: number;
}

export const LabelValuePair = (props: LabelValuePairProps) => {
    const {label, value, sml=6, smv=6} = props;
    return (
        <Grid container={true}>
            <Grid item={true} sm={sml}><Label component="span">{label}:</Label></Grid>
            <Grid item={true} sm={smv}><Typography component="span">{value}</Typography></Grid>
        </Grid>
    );
};

const Label = styled(Typography)(({theme}) =>({fontWeight: theme.typography.fontWeightMedium}));
