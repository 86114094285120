import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import {TableColumn} from "./tableDefinitions";
import * as React from "react";
import _ from "lodash";

export interface SimpleTableProps<RowType> {
    readonly columns: Array<TableColumn<RowType>>;
    readonly data: RowType[];
    readonly tableComponent?: React.ElementType;
    readonly size?: "small" | "medium";
    readonly noDataText?: string;
    readonly maxNumberOfRows?: number
    readonly maxNumberOfRowsTextFunction?: (maxRows: number, numberOfRows: number) => string
}

export function SimpleTable<RowType extends any>(props: SimpleTableProps<RowType>) {
    const {columns = [], data, tableComponent, size, noDataText, maxNumberOfRows, maxNumberOfRowsTextFunction} = props;

    const renderableData = maxNumberOfRows
        ? data.slice(0, maxNumberOfRows)
        : data

    return (
        <TableContainer component={tableComponent || Paper}>
            <Table sx={{
                minWidth: 650,
            }} size={size}>
                <TableHeader<RowType> columns={columns}/>
                <SimpleTableBody data={renderableData} columns={columns} noDataText={noDataText ?? "No Data"}/>
            </Table>
            {
                maxNumberOfRows && (
                    <Typography>{maxNumberOfRowsTextFunction
                        ? maxNumberOfRowsTextFunction(maxNumberOfRows, data.length)
                        : `show a part of ${maxNumberOfRows} lines of ${data.length} in sum`}</Typography>
                )
            }
        </TableContainer>
    );
}

export interface TableHeaderProps<RowType> {
    readonly columns: Array<TableColumn<RowType>>;
}

function TableHeader<RowType>(props: TableHeaderProps<RowType>) {
    return (
        <TableHead sx={{fontWeight: "bold"}}>
            <TableRow>
                {props.columns.map(({title, align}, i) => <TableCell sx={{fontWeight: "bold"}} align={align}
                                                                     key={i}>{title}</TableCell>)}
            </TableRow>
        </TableHead>
    );
}

export interface SimpleTableBodyProps<RowType> {
    readonly columns: Array<TableColumn<RowType>>;
    readonly data: RowType[];
    readonly noDataText: string;
}

function SimpleTableBody<RowType>(props: SimpleTableBodyProps<RowType>) {
    const {columns, data, noDataText} = props;
    if (data.length === 0) {
        return (
            <TableBody>
                <TableRow>
                    <TableCell colSpan={columns.length} align={"center"}>
                        <Typography>{noDataText}</Typography>
                    </TableCell>
                </TableRow>
            </TableBody>
        );
    }

    return (
        <TableBody>
            {
                data.map((d, dataIndex) => {
                    return (
                        <TableRow key={dataIndex}>
                            {columns.map((c, i) => <SimpleTableCell<RowType> rowIndex={dataIndex} row={d} column={c}
                                                                             key={i}/>)}
                        </TableRow>
                    );
                })
            }
        </TableBody>
    );
}

export interface SimpleTableCellProps<RowType> {
    readonly column: TableColumn<RowType>;
    readonly row: RowType;
    readonly rowIndex: number;
}

function SimpleTableCell<RowType>(props: SimpleTableCellProps<RowType>) {
    const {column, row, rowIndex} = props;
    const {align, render, dataKey} = column;

    const cellValue = _.at(row as any, [dataKey])[0];

    const innerContent = render
        ? render(cellValue, row, rowIndex)
        : cellValue ?? "";

    return (
        <TableCell align={align}>{
            typeof innerContent === "string" || React.isValidElement(innerContent)
                ? innerContent
                : (
                    <pre>
                        <code>
                            {JSON.stringify(innerContent, null, 2)}
                        </code>
                    </pre>
                )
        }</TableCell>
    );
}
