import {useController, UseControllerProps, useFormContext} from "react-hook-form";
import {UIElementProps} from "../UIElementProps";
import React from "react";
import {TextField} from "@mui/material";

export type ValidateFunction<T> = (value: any, formValues: T) => boolean;

export interface TextInputProps<T> {
    readonly type?: string;
    readonly step?: string;
    readonly maxLength?: number;
    readonly autoComplete?: string;
    readonly validateFunction?: ValidateFunction<T> | { [key: string]: ValidateFunction<T> }
}

export function TextInput<FormType = any>(props: UseControllerProps<FormType> & UIElementProps & TextInputProps<FormType>) {
    const {label, error, helperText, required, maxLength, validateFunction, step, type, autoComplete} = props;

    const {getValues} = useFormContext<FormType>();
    const {field} = useController({
        ...props, rules: {
            validate: validateFunction !== undefined
                ? typeof validateFunction === "function"
                    ? (v) => validateFunction(v, getValues() as any)
                    : mapObjectValidateFunction(validateFunction, getValues() as any)
                : undefined
        }
    });

    return (
        <TextField autoComplete={autoComplete} size={"small"} {...field} value={field.value || ""} type={type} inputRef={field.ref} fullWidth={true} label={label} error={error} helperText={helperText}
                   required={required} inputProps={{maxLength: maxLength, step: step}}/>
    );
}


function mapObjectValidateFunction<T>(object: { [key: string]: ValidateFunction<T> }, form: T): { [key: string]: (v: any) => boolean } {
    var target = {}
    Object.keys(object).forEach(k => target[k] = (v: any) => object[k](v, form))
    return target;
}
