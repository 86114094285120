import React, {useState} from "react";
import {useGetUsersOfGroup, User} from "../../../rest/user/userQueries";
import {useOrganisationModuleTranslation} from "../../../util/i18n/useModuleTranslation";

import {TabHeader} from "../../common/mui/tabHeader/TabHeader";
import {AddUserModal} from "./modals/AddUserModal";
import {isAdmin} from "../../../../security/keycloakConfig";
import {UserTable} from "./table/UserTable";
import {LoadingPaper} from "../../common/mui/paper/LoadingPaper";
import {Alert} from "@mui/material";

export interface UserListProps {
    readonly groupName: string;
}

export const UserList = (props: UserListProps) => {
    const {groupName} = props;

    const {t} = useOrganisationModuleTranslation("userList");
    const [searchText, setSearchText] = useState<string>();
    const [addUserModalOpen, setAddUserModalOpen] = useState<boolean>(false);
    return (
        <div>
            <TabHeader
                title={t("heading", {organisation: groupName.slice(1)})}
                showSearchField={true}
                onSearchFieldChange={setSearchText}
                searchFieldPlaceholder={t("search")}
                showAddButton={isAdmin()}
                addButtonText={t("add")}
                help={{
                    namespace: "organisation/general",
                    key: "general"
                }}
                onAddButtonClick={() => setAddUserModalOpen(true)}
            />
            <UserListInnerComponent groupName={groupName} searchText={searchText}/>
            {addUserModalOpen && <AddUserModal groupName={groupName} onClose={() => setAddUserModalOpen(false)}/>}
        </div>
    );
};

interface UserListInnerProps {
    readonly groupName: string;
    readonly searchText: string;
}

const UserListInnerComponent = (props: UserListInnerProps) => {
    const {groupName, searchText} = props;

    const {data, isLoading, error} = useGetUsersOfGroup(groupName);

    const filteredUsers = (searchText?.trim().length > 0) ? data.filter(d => userFilterFunction(searchText.trim(), d)) : data;

    const {t} = useOrganisationModuleTranslation("userList");
    if (isLoading) {
        return (
            <LoadingPaper/>
        );
    }

    if (error) {
        return <Alert severity="error">{t("error")}</Alert>;
    }
    return (<UserTable users={filteredUsers} groupPath={groupName}/>);
};


const userFilterFunction = (searchTerm: string, user: User): Boolean => {
    const {
        username,
        surname,
        email,
        firstName,
        fullName,
        roles,
    } = user;
    return username.includes(searchTerm)
        || surname.includes(searchTerm)
        || email.includes(searchTerm)
        || firstName.includes(searchTerm)
        || fullName.includes(searchTerm)
        || roles.join(",").includes(searchTerm);

};
