import {ReactNode} from "react";
import {FormHelperText, Grid, Typography} from "@mui/material";
import {StremoFormControl} from "./StremoFormControl";

interface FormLineProps {
    readonly label: string;
    readonly helpIcon?: ReactNode;
    readonly required?: boolean;
    readonly children: ReactNode;
    readonly hasError?: boolean;
    readonly isDirty?: boolean;
    readonly errorMessage?: string;
    readonly lineBreak?: boolean;
}

export const FormLine = (props: FormLineProps) => {
    const {label, children, required, isDirty = true, hasError, errorMessage, lineBreak, helpIcon} = props;

    const span = lineBreak ? 12 : 6;

    return (
        <Grid container={true} sx={{
            margin: "10px 0",
            width: "100%",
        }}>
            <Grid item={true} xs={span} sx={{display: "flex", alignItems: "center"}}>
                <Grid container={true}>
                    <Grid item={true}>
                        <Typography>
                            {label}{required !== false && "*"}
                        </Typography>
                    </Grid>
                    <Grid item={true} sx={{ml: "5px", display: "flex", alignItems: "center"}}>
                        {helpIcon && helpIcon}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item={true} xs={span}>
                <StremoFormControl
                    error={isDirty && hasError}
                >
                    {children}
                    {isDirty && hasError && (<FormHelperText error={true}>{errorMessage}</FormHelperText>)}
                </StremoFormControl>
            </Grid>
        </Grid>
    );
};
