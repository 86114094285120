import {Alert, DialogContentText} from "@mui/material";
import {useDeleteUserMutation, User} from "../../../../rest/user/userQueries";
import {useOrganisationModuleTranslation} from "../../../../util/i18n/useModuleTranslation";
import {SimpleDialog} from "../../../common/mui/dialog/SimpleDialog";

interface DeleteUserProps {
    readonly user: User;
    readonly groupPath: string;
    readonly onClose: () => void;
}

export const DeleteUserModal = (props: DeleteUserProps) => {
    const {user, groupPath, onClose} = props;
    const {t} = useOrganisationModuleTranslation("userList.deleteUserModal");
    const mutation = useDeleteUserMutation(groupPath, user.id);
    const onDelete = () => mutation.mutateAsync()
        .then(onClose)

    return (
        <SimpleDialog
            open={true}
            onClose={onClose}
            aria-labelledby="delete-user-from-organisation"
            title={t("title", {username: user.username})}
            footerActionProps={{
                cancelButton: {
                    text: t("cancel"),
                    onClick: onClose,
                },
                submitButton: {
                    text: t("submit"),
                    onClick: onDelete
                }
            }}
        >
            {mutation.error &&
            <Alert severity="error">{t("feedback.error." + mutation.error.translationCode)}</Alert>}
            <DialogContentText>
                {t("content", {firstName: user.firstName, surname: user.surname})}
            </DialogContentText>
            <DialogContentText>
                {t("deleteAllPrivateProjects")}
            </DialogContentText>
        </SimpleDialog>
    );
};
