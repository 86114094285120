import axios from "axios";

export const defaultBasePath = "help";

export const callGetNamespace = (basePath: string, lng: string, namespace: string): Promise<any> => axios.get(getUrl(basePath, lng, namespace)).then(({data}) => Promise.resolve(data))

const getUrl = (basePath: string, lng: string, namespace: string) => {
    const slashHolder = (basePath.startsWith("/") || (basePath.trim() === "" && lng.startsWith("/")))
        ? ""
        : "/";

    return slashHolder + concatUrl(basePath, lng, namespace) + ".json"
}

const concatUrl = (basePath: string, lng: string, namespace: string): string => concatTwoParts(concatTwoParts(basePath, lng), namespace);

const concatTwoParts = (part1: string, part2: string): string => {
    if (part1.endsWith("/") || part2.startsWith("/")) {
        return `${part1}${part2}`;
    }
    return `${part1}/${part2}`;
};
