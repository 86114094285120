import {ReactNode} from "react";
import {Box, Paper, Typography} from "@mui/material";
import {H3} from "../headings/Headings";

export interface SimplePaperProps {
    readonly title?: ReactNode;
    readonly smallTitle?: ReactNode;
    readonly subTitle?: ReactNode;
    readonly children: ReactNode;
    readonly onClick?: () => void;
}

export const SimplePaper = (props: SimplePaperProps) => {
    const {title, subTitle, children, onClick, smallTitle} = props;

    return (
        <Paper sx={{
            cursor: onClick && "pointer"
        }} onClick={onClick}>
            <Box sx={{p: 2, overflow: "hidden"}}>{
                title && (
                    <H3 small={smallTitle}>
                        {title}
                        <Typography component="span" sx={{
                            ml: "10px",
                        }}>{subTitle}</Typography>
                    </H3>)}
                <div>
                    {children}
                </div>
            </Box>
        </Paper>
    );
};
