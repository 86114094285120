import {User} from "../../../../rest/user/userQueries";
import {useOrganisationModuleTranslation} from "../../../../util/i18n/useModuleTranslation";
import {TableColumn} from "../../../common/mui/table/tableDefinitions";
import {SimpleTable} from "../../../common/mui/table/SimpleTable";
import {useState} from "react";
import {isAdmin, userInfo} from "../../../../../security/keycloakConfig";
import {EditUserModal} from "../modals/EditUserModal";
import {ClickableIcon, DeleteIconButton, EditIconButton} from "../../../common/mui/icons";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import {DeleteUserModal} from "../modals/DeleteUserModal";
import {ResetCredentialsModal} from "../modals/ResetCredentialsModal";
import {Button} from "@mui/material";

export interface UserTableProps {
    readonly users: User[];
    readonly groupPath: string;
}

export const UserTable = (props: UserTableProps) => {
    const {users, groupPath} = props;

    const {t} = useOrganisationModuleTranslation("userTable");

    const columns: Array<TableColumn<User>> = [
        {
            title: t("header.username"),
            dataKey: "username",
        },
        {
            title: t("header.firstName"),
            dataKey: "firstName",
        },
        {
            title: t("header.surname"),
            dataKey: "surname",
        },
        {
            title: t("header.email"),
            dataKey: "email",
        },
        {
            title: t("header.roles"),
            render: (roles: string[]) => (<span>{roles.join(", ")}</span>),
            dataKey: "roles",
        },
        {
            title: t("header.actions"),
            render: (id: string, user) => <ActionCell user={user} groupPath={groupPath}/>,
            dataKey: "id",
        },
    ];

    return (
        <SimpleTable columns={columns} data={users}/>
    );
};

interface ActionCellProps {
    readonly user: User;
    readonly groupPath: string;
}

const ActionCell = (props: ActionCellProps) => {
    const {t} = useOrganisationModuleTranslation("userTable.actions");
    const [vD, setVD] = useState<boolean>(false);
    const [vE, setVE] = useState<boolean>(false);
    const [vRC, setVRC] = useState<boolean>(false);
    if (!isAdmin()) {
        if (props.user.id === userInfo.id) {
            return (
                <>
                    <Button onClick={() => setVE(true)}>{t("edit")}</Button>
                    {
                        vE &&
                        <EditUserModal user={props.user} groupPath={props.groupPath} onClose={() => setVE(false)}/>
                    }
                </>
            );
        }
        return <></>;
    }
    return (
        <>
            <EditIconButton aria-label="edit" toolTip={t("edit")} onClick={() => setVE(true)}/>
            <ClickableIcon aria-label="reset-credentials" toolTip={t("resetCredentials")} onClick={() => setVRC(true)}>
                <VpnKeyIcon/>
            </ClickableIcon>
            <DeleteIconButton aria-label={"delete"} toolTip={t("delete")} onClick={() => setVD(true)}/>

            {vD && <DeleteUserModal user={props.user} groupPath={props.groupPath} onClose={() => setVD(false)}/>}
            {vE && <EditUserModal user={props.user} groupPath={props.groupPath} onClose={() => setVE(false)}/>}
            {vRC && <ResetCredentialsModal user={props.user} onClose={() => setVRC(false)}/>}
        </>
    );
};
