import {useTranslation} from "react-i18next";
import {Alert} from "@mui/material";
import {ExceptionResponse} from "../../../../rest/exceptions";

export interface ErrorAlertProps {
    readonly error: ExceptionResponse;
}

/**
 * @deprecated
 * @param props
 * @constructor
 */
export const ErrorAlert = (props: ErrorAlertProps) => {
    const {t} = useTranslation();
    return (
        <Alert severity="error">{props.error.translationCode
            ? t("response.error." + props.error.translationCode)
            : props.error.message}</Alert>
    );
};
